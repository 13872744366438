import type { ResponseError } from '@capturi/request'
import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { type UseMutationResult, useMutation } from '@tanstack/react-query'

export const useCopyCasesList = (): UseMutationResult<
  unknown,
  ResponseError,
  { integrationType: string },
  ResponseError
> => {
  const toast = useToast()
  return useMutation({
    mutationFn: ({ integrationType }) => {
      return request.get<unknown>(
        `importer/${integrationType.toLowerCase()}/list-cases`,
      )
    },
    onSuccess: (resp) => {
      navigator.clipboard.writeText(JSON.stringify(resp, undefined, 3))
      toast({ status: 'info', title: 'Copied list to clipboard' })
    },
    onError: (error) => {
      toast({ status: 'error', title: error.message })
    },
  })
}
