import {
  useConversations,
  useRandomConversations,
} from '@capturi/api-conversations'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  PhoneSegmentBuilderState,
  SingleSegmentContainer,
  TextSegmentBuilderState,
  useFilterDefinitions,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
  useSegmentStatesContext,
} from '@capturi/filters'
import {
  ErrorBoundary,
  usePageTitle,
  useScrollable,
} from '@capturi/react-utils'
import { PageHeading } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Box, Divider, Flex, Grid, GridItem, HStack } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import {
  FC,
  startTransition,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import isEqual from 'react-fast-compare'
import { Navigate, useNavigate, useParams } from 'react-router'
import { globalSearchStore } from 'state/useGlobalSearch'
import ConversationDetails from '../../../components/ConversationDetails'
import { SearchInputWithCounters } from '../../../components/Search/SearchInputWithCounters'
import casesRoutes from '../../Cases/routes'
import routes, { ConversationPageView } from '../routes'
import ConversationsTable from './ConversationsTable'
import StartRandomizerModal from './StartRandomizerModal'

const ConversationsPage: FC = () => {
  usePageTitle(t`Conversations`)
  const { uid: conversationUidParam } = useParams()

  const { states: allSegmentStates } = useSegmentStatesContext()
  const [numberOfRandomConversations, setNumberOfRandomConversations] =
    useState(20)
  const [isRandomizerEnabled, setIsEnabledRandomizer] = useState(false)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)

  const segmentState = useFirstPhoneSegmentState()
  const [prevSegmentStateValues, setPrevSegmentStateValues] = useState(
    segmentState.values,
  )

  const periode = useFilterPeriodContext()
  const [prevPeriode, setPrevPeriode] = useState(periode.periodDef)

  const searchPhrases = useStore(globalSearchStore)
  const [prevGlobalSearchPhrase, setPrevGlobalSearchPhrase] =
    useState(searchPhrases)

  const filter = segmentState.getFilterRequestModel()

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingRegularConversations,
    isInSearchMode,
    canLoadMoreSearchResults,
    loadMoreSearchResults,
  } = useConversations({ filter, searchPhrases })

  const ref = useRef<HTMLDivElement | null>(null)
  const height = useScrollable(ref)

  const navigate = useNavigate()

  const [openRandomizer] = useModal(StartRandomizerModal)

  const randomData = useRandomConversations({
    filter: segmentState.getFilterRequestModel(),
    isEnabled: isRandomizerEnabled,
    numberOfRandomConversations: numberOfRandomConversations,
  })

  const handleRefetchRandomConversations = useCallback(() => {
    // We set the uid to undefined, so the top result is selected
    handleNavigateToConversation('')
    randomData.refetch()
  }, [randomData])

  const conversations = isRandomizerEnabled
    ? randomData.data?.pages.flat()
    : data?.pages.flat()

  const [prevRandomData, setPrevRandomData] = useState(
    (conversations || []).map((d) => d.uid),
  )

  const isLoading = isRandomizerEnabled
    ? randomData.isLoading
    : isLoadingRegularConversations

  const handleConversationTabOpen = useCallback(
    (conversationTab: ConversationPageView): void => {
      if (!conversationUidParam) {
        return
      }
      startTransition(() => {
        navigate(routes.conversation(conversationUidParam, conversationTab), {
          replace: true,
        })
      })
    },
    [navigate, conversationUidParam],
  )

  const handleNavigateToConversation = useCallback(
    (uid: string, tabName?: ConversationPageView): void => {
      navigate(routes.conversation(uid, tabName))
    },
    [navigate],
  )

  useEffect(() => {
    if (
      isLoading === true &&
      (segmentState.values !== prevSegmentStateValues ||
        periode.periodDef !== prevPeriode ||
        searchPhrases !== prevGlobalSearchPhrase)
    ) {
      handleNavigateToConversation('')
    }

    if (
      isLoading === false &&
      conversations?.length &&
      segmentState.values !== prevSegmentStateValues
    ) {
      setPrevSegmentStateValues(segmentState.values)
      handleNavigateToConversation(conversations[0].uid)
      return
    }
    if (
      isLoading === false &&
      conversations?.length &&
      periode.periodDef !== prevPeriode
    ) {
      setPrevPeriode(periode.periodDef)
      handleNavigateToConversation(conversations[0].uid)
      return
    }
    if (
      isLoading === false &&
      conversations?.length &&
      searchPhrases !== prevGlobalSearchPhrase
    ) {
      setPrevGlobalSearchPhrase(searchPhrases)
      handleNavigateToConversation(conversations[0].uid)
      return
    }
    if (
      isRandomizerEnabled &&
      isLoading === false &&
      conversations?.length &&
      !isEqual(
        conversations.map((d) => d.uid),
        prevRandomData,
      )
    ) {
      setPrevRandomData(conversations.map((d) => d.uid))
      handleNavigateToConversation(conversations[0].uid)
      return
    }

    // No selection but we have conversations - select the first one
    if (
      !conversationUidParam &&
      conversations?.length &&
      conversations[0].uid
    ) {
      handleNavigateToConversation(conversations[0].uid)
    }
  }, [
    isRandomizerEnabled,
    isLoading,
    segmentState.values,
    prevSegmentStateValues,
    periode.periodDef,
    prevPeriode,
    searchPhrases,
    prevGlobalSearchPhrase,
    conversationUidParam,
    conversations,
    handleNavigateToConversation,
    prevRandomData,
  ])

  const handleSegmentStateChange = useCallback(
    (state: PhoneSegmentBuilderState | TextSegmentBuilderState) => {
      if (state.channel === 'email') {
        navigate(casesRoutes.list())
      } else {
        navigate(routes.conversation(''), {
          replace: true,
        })
      }
    },
    [navigate],
  )

  if (allSegmentStates.length > 0 && allSegmentStates[0].channel === 'email') {
    return <Navigate to={casesRoutes.list()} replace />
  }

  return (
    <Flex
      className="conversations-container"
      flexDir="column"
      h={height}
      overflowY="hidden"
      ref={ref}
    >
      <Box mb={4} flex="0 1 auto">
        <Flex align="flex-start" justify="space-between" mb={8}>
          <PageHeading h={8} lineHeight="shorter">
            <Trans>All conversations</Trans>
          </PageHeading>
          <HStack alignItems="flex-start">
            <SearchInputWithCounters />
            <Box>
              <FilterPeriodSelectContainer />
            </Box>
          </HStack>
        </Flex>
        <SingleSegmentContainer
          disabledChannels={[]}
          showRandomizerButton={currentUser.permissions.qualityAssurance}
          isRandomizerEnabled={isRandomizerEnabled}
          onToggleRandomizer={(state) => {
            if (state === 'on') {
              openRandomizer({
                numberOfConversations: numberOfRandomConversations,
                setEnableRandomizer: () => {
                  // setSelectedConversationUid(undefined)
                  handleNavigateToConversation('')
                  randomData.refetch() //to force refresh when use clicks the button again
                  setIsEnabledRandomizer(true)
                },
                setNumberOfConversations: (n: number) =>
                  setNumberOfRandomConversations(n),
              })
            } else if (state === 'off') {
              setIsEnabledRandomizer(false)
              handleNavigateToConversation('')
              // setSelectedConversationUid(undefined)
            }
          }}
          filterDefinitions={filterDefinitions}
          onStateChange={handleSegmentStateChange}
        />
      </Box>
      <ErrorBoundary>
        <Grid
          templateColumns={{
            base: 'minmax(12.5rem, 19rem) 1px minmax(auto, 100%)',
          }}
          gap={2}
          flex="1 1 auto"
          overflowY="hidden"
        >
          <GridItem h="100%" overflowY="hidden">
            <ConversationsTable
              error={error}
              isLoading={isLoading}
              selectedConversationUid={conversationUidParam}
              conversations={conversations}
              handleLoadMore={
                isInSearchMode ? loadMoreSearchResults : fetchNextPage
              }
              isRandomizerEnabled={isRandomizerEnabled}
              onConversationSelected={(uid) => {
                if (uid) handleNavigateToConversation(uid)
              }}
              // randomConversationsIsLoading={randomData.isFetching}
              canLoadMore={hasNextPage || canLoadMoreSearchResults}
              isFetchingNextPage={isFetchingNextPage}
              refetchRandomConversations={handleRefetchRandomConversations}
            />
          </GridItem>
          <GridItem>
            <Divider orientation="vertical" />
          </GridItem>
          <GridItem minW="52rem" w="100%" h="100%" overflowY="hidden">
            {conversationUidParam && (
              <ConversationDetails
                uid={conversationUidParam}
                onTabOpen={handleConversationTabOpen}
                navigateToConversation={handleNavigateToConversation}
                isSidebarView={false}
              />
            )}
          </GridItem>
        </Grid>
      </ErrorBoundary>
    </Flex>
  )
}

export default ConversationsPage
