import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Tag,
  TagCloseButton,
  TagLabel,
} from '@chakra-ui/react'
import {
  type ChangeEvent,
  type ClipboardEvent,
  type FC,
  type KeyboardEvent,
  ReactElement,
  useRef,
  useState,
} from 'react'

type Props = {
  fieldKey: string
  value: string[]
  onChange: (value: string[]) => void
  splitOnWhiteSpace?: boolean
  isDisabled?: boolean
  label: string
  splitCharacters?: ('space' | 'comma')[]
}
const regexKeyMap = { space: '\\s', comma: ',' } as const
const keyboardKeyMap = { space: ' ', comma: ',' } as const

export const ChipsField: FC<Props> = ({
  value,
  onChange,
  fieldKey,
  splitCharacters = [],
  label,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [newPhrase, setNewPhrase] = useState<string>('')

  const handlePaste = (event: ClipboardEvent<HTMLDivElement>): void => {
    event.preventDefault()
    const text = event.clipboardData.getData('Text')

    const reg = new RegExp(
      ['\n', ...splitCharacters.map((s) => regexKeyMap[s])].join('|'),
    )

    const numbers = text
      .split(reg)
      .map((w) => w.trim())
      .filter(Boolean)

    const newState = [...value, ...numbers]
    onChange(newState)
    setNewPhrase('')
    inputRef?.current?.focus()
  }

  const handleAdd = (): void => {
    if (newPhrase) {
      onChange([...value, newPhrase])
      setNewPhrase('')
    }
  }

  const removePhrase = (index: number): void => {
    const newState = [...value]
    newState.splice(index, 1)
    onChange(newState)
    inputRef?.current?.focus()
  }

  const handleKeypress = (e: KeyboardEvent<HTMLInputElement>): void => {
    const splitKeys = [
      'Enter',
      'Tab',
      ...splitCharacters.map((s) => keyboardKeyMap[s]),
    ]

    if (newPhrase && splitKeys.includes(e.key)) {
      e.preventDefault()
      handleAdd()
    }
  }

  const renderPhrase = (phrase: string, index: number): ReactElement => {
    return (
      <Tag m="1" justifyContent="space-between" key={index} borderRadius="999">
        <TagLabel>{phrase}</TagLabel>
        <TagCloseButton onClick={() => removePhrase(index)} />
      </Tag>
    )
  }

  return (
    <FormControl key={fieldKey}>
      <FormLabel>{label}</FormLabel>

      <Flex
        onClick={() => inputRef?.current?.focus()}
        border="solid 1px"
        borderColor="border.light"
        borderRadius="md"
        height="100%"
        transition="200ms padding ease-out"
        padding={1}
        justify="space-between"
        flexDirection="column"
        _focusWithin={{
          borderColor: 'primary.500',
          boxShadow: '0 0 0 1px var(--chakra-colors-primary-500)',
        }}
      >
        <Flex wrap="wrap" alignItems={'center'}>
          {value.map(renderPhrase)}
          <Box
            as="input"
            fontSize="sm"
            px={2}
            my="6px"
            outline={0}
            // placeholder={placeholder}
            ref={inputRef}
            onBlur={handleAdd}
            onKeyDown={handleKeypress}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setNewPhrase(e.target.value.toLocaleLowerCase())
            }
            value={newPhrase}
            onPaste={handlePaste}
          />
        </Flex>
      </Flex>
    </FormControl>
  )
}
