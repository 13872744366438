import analytics from '@capturi/analytics'
import {
  Box,
  Flex,
  Heading,
  Skeleton,
  Text,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { DefaultFallbackComponent } from '@capturi/react-utils'
import { Button } from '@capturi/ui-components'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router'

import SessionFailedProcessing from '../../../components/KeyTopics/FailedStates/SessionFailedProcessing'
import SessionNotFound from '../../../components/KeyTopics/FailedStates/SessionNotFound'
import { useKeyTopics } from '../../../hooks/useKeyTopics'
import { routes } from '../../KeyTopics/routes'
import { AnimateChangeInHeight } from '../Shared/AnimateHeight'
import {
  TopConversationDriversWidgetCard,
  TopConversationDriversWidgetCardSkeleton,
} from './TopConversationDriversWidgetCard'
import { selector } from './selector'

type Props = {
  keyTopicsSessionUid: string | undefined
  startNewSession: () => void
}

const TopConversationDriversWidgetSkeleton: React.FC = () => {
  return (
    <Box>
      <Skeleton height="21px" width="250px" mb="1" />
      <Skeleton height="14px" width="70%" mb="1" />
      <VStack alignItems="stretch" spacing={4}>
        <TopConversationDriversWidgetCardSkeleton />
      </VStack>
    </Box>
  )
}

export const TopConversationDriversWidget: React.FC<Props> = ({
  keyTopicsSessionUid,
  startNewSession,
}) => {
  const navigate = useNavigate()
  const { data, isLoading, error } = useKeyTopics({
    select: selector,
  })

  const { top3KeyTopicsPercentage, topKeyTopic } = useMemo(() => {
    if (
      !data ||
      data.status !== 'ready' ||
      data.keyTopicClusters.length === 0
    ) {
      return { top3KeyTopicsPercentage: undefined, topKeyTopic: undefined }
    }

    const top3KeyTopicsPercentage = data.keyTopicClusters
      ?.slice(0, 3)
      .reduce((acc, curr) => acc + curr.percentage, 0)
    const topKeyTopic = data.keyTopicClusters[0]
    return { top3KeyTopicsPercentage, topKeyTopic }
  }, [data])

  if (error) return <DefaultFallbackComponent error={error} />
  if (data?.status === 'failed')
    return <SessionFailedProcessing startNewSession={startNewSession} />
  if (data?.status === 'gone') {
    return <SessionNotFound startNewSession={startNewSession} />
  }
  if (isLoading || !data || data.status === 'loading') {
    return (
      <AnimateChangeInHeight overflowYVisble>
        <TopConversationDriversWidgetSkeleton />
      </AnimateChangeInHeight>
    )
  }

  return (
    <AnimateChangeInHeight overflowYVisble>
      <Box minH={data.keyTopicClusters.length > 0 ? '368px' : undefined}>
        <Heading
          fontSize="2xl"
          textShadow="textExtended"
          data-stonly="Top conversation drivers"
          width="fit-content"
        >
          <Trans>Top conversation drivers</Trans>
        </Heading>
        <Flex>
          <Text flex="1" fontSize="sm" color="gray.600">
            {top3KeyTopicsPercentage !== undefined && (
              <chakra.span>
                <chakra.span fontWeight="medium">
                  <Trans>
                    Top three categories make up{' '}
                    {top3KeyTopicsPercentage.toFixed()}%
                  </Trans>
                </chakra.span>{' '}
                <Trans>of all conversations.</Trans>
              </chakra.span>
            )}{' '}
            {topKeyTopic !== undefined && (
              <>
                <chakra.span fontWeight="medium">
                  {topKeyTopic.label}
                </chakra.span>{' '}
                <Trans>and related topics is the largest at </Trans>{' '}
                {topKeyTopic.percentage.toFixed()}%{' '}
                {topKeyTopic.graphData.length > 0 && (
                  <chakra.span>
                    <Trans>with primary driver being</Trans>
                    <chakra.span fontWeight="medium">
                      {' '}
                      {topKeyTopic.graphData[0].label}
                    </chakra.span>
                    .
                  </chakra.span>
                )}
              </>
            )}
          </Text>
          {keyTopicsSessionUid && (
            <Button
              onClick={() => {
                analytics.event('top_drivers_explore_more')
                navigate(routes.root)
              }}
              primary
              size="xs"
              variant="link"
            >
              <Trans>Explore more</Trans>
            </Button>
          )}
        </Flex>
        <TopConversationDriversWidgetCard
          keyTopicsSessionUid={keyTopicsSessionUid}
          data={data}
        />
      </Box>
    </AnimateChangeInHeight>
  )
}
