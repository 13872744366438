import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/macro'
import React from 'react'

import { pctFormat } from '../../../../pages/analytics/shared/utils'

type TextDrawerBenchmarksProps = {
  conversationCount: number
  conversationsPct: number
  percentageOfTotal: number
}

export const TextDrawerBenchmarks: React.FC<TextDrawerBenchmarksProps> = ({
  conversationCount,
  conversationsPct,
  percentageOfTotal,
}) => {
  return (
    <Card shadow="none" border="1px solid" borderColor="gray.200" minH="11rem">
      <CardBody pl={4} pr={4} pb={4} pt={2} h="100%" overflowY="auto">
        <HStack justifyContent="space-around" h="full">
          <Flex textAlign="center" direction="column" gap={2} justify="center">
            <CardHeader
              pl={0}
              pr={0}
              pb={1}
              pt={0}
              fontSize="sm"
              fontWeight="medium"
              color="gray.600"
              textAlign="center"
            >
              <Trans>Total conversations</Trans>
            </CardHeader>
            <Flex alignItems="baseline" justifyContent="center">
              <Text lineHeight="1.2" fontSize="4xl" fontWeight="medium">
                {conversationCount}
              </Text>
              {conversationsPct !== 0 && (
                <Text color="gray.600" fontSize="3xl" lineHeight="1.2" ml={1}>
                  ({i18n.number(conversationsPct / 100, pctFormat)})
                </Text>
              )}
            </Flex>

            <Flex color="gray.600" align="center" justify="center">
              {percentageOfTotal > 0 && (
                <Text align="center" fontSize="lg" ml={1}>
                  {i18n.number(percentageOfTotal / 100, pctFormat)}{' '}
                  <Trans>of total</Trans>
                </Text>
              )}
            </Flex>
          </Flex>
        </HStack>
      </CardBody>
    </Card>
  )
}
