import { useSet } from '@capturi/react-utils'
import { Checkbox, CheckboxGroup, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import CsvCheckbox from './CsvCheckbox'

type Props = {
  group: {
    category: string
    show?: boolean | undefined

    data: {
      label: string
      value: string
      hidden?: boolean
    }[]
  }
  set: ReturnType<typeof useSet<string>>
  isLoading: boolean
}

const CsvCheckboxGroup: React.FC<Props> = ({ group, set, isLoading }) => {
  const isAllChecked = group.data.every((item) => set.has(item.value))
  const isIndeterminate =
    !isAllChecked && group.data.some((item) => set.has(item.value))

  const handleCheckAll = (
    data: {
      label: string
      value: string
    }[],
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    data.forEach((item) => {
      if (e.target.checked) {
        set.add(item.value)
      } else {
        set.delete(item.value)
      }
    })
  }
  return (
    <CheckboxGroup>
      <Flex flexDir="column">
        <Checkbox
          isDisabled={isLoading}
          isChecked={isAllChecked}
          isIndeterminate={isIndeterminate}
          onChange={(e) => {
            handleCheckAll(group.data, e)
          }}
        >
          <Text fontWeight="bold">{group.category}</Text>
        </Checkbox>
        <Flex flexDir="column" ml={6} gap={1} mb={4}>
          {group.data.map((item, index) => {
            if (item.hidden) return
            return (
              <CsvCheckbox
                key={index}
                data={item}
                isLoading={isLoading}
                onCheck={set.add}
                onUncheck={set.delete}
                isChecked={set.has}
              />
            )
          })}
        </Flex>
      </Flex>
    </CheckboxGroup>
  )
}

export default CsvCheckboxGroup
