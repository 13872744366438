import { useModal } from '@capturi/use-modal'
import { FormControl, FormLabel, Input } from '@chakra-ui/react'
import { format } from 'date-fns/fp'
import type { FC } from 'react'
import { Etrack1FindTsControl } from '../Etrack1FindTsControl'
type Props = { integrationType: string; integrationUid: string } & (
  | {
      value: number
      onChange: (values: number) => void
      isDisabled?: boolean
      type: 'number'
    }
  | {
      value: string
      onChange: (values: string) => void
      isDisabled?: boolean
      type: 'string'
    }
  | {
      value: Date
      onChange: (values: Date) => void
      isDisabled?: boolean
      type: 'date'
    }
)

const toTime = format("yyyy-MM-dd'T'HH:mm")

export const ContinuationTokenField: FC<Props> = ({
  type,
  onChange,
  value,
  isDisabled,
  integrationType,
  integrationUid,
}) => {
  const [openFindTsControl] = useModal(Etrack1FindTsControl)

  return (
    <FormControl mt="2" key={'continuationToken'}>
      <FormLabel mb="0" ml="1" htmlFor={'continuationToken'}>
        Continuation Token
      </FormLabel>
      <Input
        type={type === 'date' ? 'datetime-local' : 'text'}
        isDisabled={isDisabled}
        id="continuationToken"
        value={type === 'date' ? toTime(value) : value}
        onChange={(event) =>
          type === 'date'
            ? onChange(new Date(event.currentTarget.value))
            : type === 'number'
              ? onChange(Number(event.currentTarget.value))
              : onChange(event.currentTarget.value)
        }
        autoComplete="none"
        onClick={
          integrationType === 'etrack1' && type === 'number'
            ? () =>
                openFindTsControl({
                  integrationUid: integrationUid,
                  onSubmit: onChange,
                })
            : undefined
        }
      />
    </FormControl>
  )
}
