import { Icon } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useMemo, useCallback } from 'react'
import { MdDataUsage } from 'react-icons/md'

import { Highlight } from '@capturi/ui-components'

import { KeyTopicSelect } from '@capturi/filters'
import { TextFilterPopover } from '../TextFilterPopover'
import { MultiValueFilterItem } from '../useTextFilter'
import { TextFilterComponentProps } from './types'

// Popover component for the key topics. We will pass this to TextFilterPopover.
const KeyTopicsPopoverContent: React.FC<{
  item: MultiValueFilterItem
  onChangeValue: (item: MultiValueFilterItem) => void
  onClose: () => void
  onRemove: (itemUid: string) => void
}> = ({ item, onChangeValue, onClose }) => {
  const handleSetValue = useCallback(
    (newValues: string[]) => {
      onChangeValue({ ...item, values: newValues })
    },
    [item, onChangeValue],
  )

  return (
    <KeyTopicSelect
      channel="email"
      value={item.values ?? []}
      setValue={handleSetValue}
      onClose={onClose}
    />
  )
}

export const KeyTopicsFilter: React.FC<
  TextFilterComponentProps<MultiValueFilterItem>
> = ({ item, isOpen, onOpen, onClose, onChangeValue, onRemove }) => {
  const hasValue = useMemo(() => (item.values?.length ?? 0) > 0, [item.values])

  return (
    <TextFilterPopover
      isOpen={isOpen}
      item={item}
      hasValue={hasValue}
      onChangeValue={onChangeValue}
      onClose={onClose}
      onOpen={onOpen}
      onRemove={onRemove}
      filterCriteriaButtonProps={{
        leftIcon: <Icon boxSize="20px !important" as={MdDataUsage} />,
        children: (
          <>
            <Highlight mr="1">
              <Trans>Key topics</Trans>
            </Highlight>
            {item.inverted ? (
              <Trans>
                is <Highlight>NOT</Highlight>
              </Trans>
            ) : (
              <Trans>is</Trans>
            )}
            {` ${item.values?.join(', ') ?? ''}`}
          </>
        ),
      }}
      PopoverComponent={KeyTopicsPopoverContent}
    />
  )
}
