import { Box, Card, CardBody, Text, chakra } from '@chakra-ui/react'
import { formatDistanceToNow, subDays } from 'date-fns'
import { FC, type ReactElement } from 'react'
import Loader from '../../../../Loader'
import { useSystemSettings } from '../../../../shared/useSystemSettings'
import { Title } from '../../../../utils'

type Props = { organizationUid: string }

const TimeRow = (key: string, timeSpan: string | null): ReactElement => {
  const days = timeSpanToNumber(timeSpan)

  const text = days ? formatDistanceToNow(subDays(new Date(), days)) : 'never'

  return (
    <Box
      as="tr"
      key={key}
      _hover={{ color: 'primary.500' }}
      cursor="default"
      transition="color ease-in 100ms"
    >
      <Box textAlign="right" as="td" color="gray" pr="2">
        {key}
      </Box>
      <Box as="td">
        <chakra.span fontWeight="bold">{text}</chakra.span>
        <chakra.span ml="2">{days} days</chakra.span>
      </Box>
    </Box>
  )
}

const BoolRow = (key: string, val: boolean): ReactElement => {
  return (
    <Box as="tr" key={key} _hover={{ color: 'primary.500' }} cursor="default">
      <Box textAlign="right" as="td" color="gray" pr="2">
        {key}
      </Box>
      <Box as="td">
        <chakra.span fontWeight="bold">{val ? 'Yes' : 'No'}</chakra.span>
      </Box>
    </Box>
  )
}

const timeSpanToNumber = (timeSpan: string | null): number | undefined =>
  timeSpan ? Number(timeSpan.split('.')[0]) : undefined

export const DeletionPolicy: FC<Props> = ({ organizationUid }) => {
  const { data, isError, error, isPending } = useSystemSettings(organizationUid)

  if (isPending) return <Loader />
  if (isError) return <Text>{error.message}</Text>

  const {
    anonymizeAsrData,
    audioSnippetTimeToLive,
    caseTimeToLive,
    conversationHardTimeToLive,
    conversationSoftTimeToLive,
    deleteCustomer,
  } = data.deletionStrategy
  return (
    <Card>
      <CardBody>
        <Title>Deletion Policy</Title>

        <table>
          <tbody>
            {TimeRow('Audio Snippet Time To Live', audioSnippetTimeToLive)}
            {TimeRow('Case Time To Live', caseTimeToLive)}
            {TimeRow(
              'Conversation Soft Time To Live',
              conversationSoftTimeToLive,
            )}
            {TimeRow(
              'Conversation Hard Time To Live',
              conversationHardTimeToLive,
            )}
            {BoolRow('Anonymize ASR Data', anonymizeAsrData)}
            {BoolRow('Delete Customer', deleteCustomer)}
          </tbody>
        </table>
      </CardBody>
    </Card>
  )
}
