import type { Summary } from '@capturi/api-summaries'
import { InfiniteData } from '@tanstack/react-query'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

interface UseAskAiSummariesProps {
  summariesData: InfiniteData<Summary[]> | undefined
  fetchNextPage: () => Promise<unknown>
  hasNextPage: boolean | undefined
  resetKey?: string
}

export const ASK_AI_LOAD_MORE_STEP = 5

// This hook limits the amount of summaries we load at a time
// Required to limit the amount of LLM calls
export const useAskAiSummaries = ({
  summariesData,
  fetchNextPage,
  hasNextPage,
  resetKey = '',
}: UseAskAiSummariesProps) => {
  const totalSummaries = useMemo(() => {
    return summariesData?.pages.reduce((acc, page) => acc + page.length, 0) ?? 0
  }, [summariesData])

  // How many summaries to show so far
  const [aiSummariesToShow, setAiSummariesToShow] = useState<number>(
    ASK_AI_LOAD_MORE_STEP,
  )

  const prevResetKeyRef = useRef<string>(resetKey)
  useEffect(() => {
    if (prevResetKeyRef.current !== resetKey) {
      setAiSummariesToShow(ASK_AI_LOAD_MORE_STEP)
      prevResetKeyRef.current = resetKey
    }
  }, [resetKey])
  const resetAiSummariesPagination = useCallback(() => {
    setAiSummariesToShow(ASK_AI_LOAD_MORE_STEP)
    prevResetKeyRef.current = resetKey
  }, [resetKey])

  // Whether or not more data remains to be shown or fetched
  const hasNextPageAssumingAskAiMode = useMemo(() => {
    return aiSummariesToShow < totalSummaries || hasNextPage
  }, [aiSummariesToShow, totalSummaries, hasNextPage])

  const handleLoadMore = useCallback(async () => {
    if (!hasNextPageAssumingAskAiMode) return
    if (
      aiSummariesToShow + ASK_AI_LOAD_MORE_STEP > totalSummaries &&
      hasNextPage
    ) {
      await fetchNextPage()
    }

    setAiSummariesToShow((prev) => prev + ASK_AI_LOAD_MORE_STEP)
  }, [
    aiSummariesToShow,
    hasNextPageAssumingAskAiMode,
    totalSummaries,
    hasNextPage,
    fetchNextPage,
  ])

  return {
    aiSummariesToShow,
    handleLoadMore,
    hasNextPage: hasNextPageAssumingAskAiMode,
    resetAiSummariesPagination,
  }
}
