import {
  GroupBase,
  PopoutSelect,
  ReactSelectProps,
  SelectOption,
  SelectOptionBase,
  createFilter,
} from '@capturi/ui-select'
import { Plural, t } from '@lingui/macro'
import React, { useMemo } from 'react'

const defaultFilter = createFilter({})

export type FilterCriteriaSelectProps<
  Option extends SelectOptionBase = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = ReactSelectProps<Option, IsMulti, Group> & {
  onClose?: () => void
  showFooter?: boolean
}

const filterOption = (
  option: {
    label: string
    value: string
    data: SelectOption
  },
  rawInput: string,
): boolean => {
  // Do not filter options in the the selected options group
  if (option.data.isSelectedGroupOption === true) return true
  // Otherwise just use deafult filtering
  return defaultFilter(option, rawInput)
}

const noOptionsMessage = () => t`No options`

const formatSelectedCount = (count: string | number) => (
  <Plural value={count} one={'1 selected'} other={'# selected'} />
)

export function FilterCriteriaSelect<
  Option extends SelectOptionBase = SelectOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  placeholder,
  onChange,
  id,
  isMulti,
  value,
  options: optionsProp,
  onClose,
  components,
  showFooter = isMulti,
  ...props
}: FilterCriteriaSelectProps<Option, IsMulti, Group>): React.ReactElement {
  const memoizedOptions = useMemo(() => {
    if (!isMulti) return optionsProp

    const enhanceSelectedOption = (option: Option): Option => ({
      ...option,
      isSelectedGroupOption: true,
    })

    const selectedGroup: GroupBase<Option> = {
      label: t`Selected`,
      options:
        value == null
          ? []
          : Array.isArray(value)
            ? value.map(enhanceSelectedOption)
            : [enhanceSelectedOption(value as Option)],
    }

    const allOptionsGroup: GroupBase<Option> = {
      label: t`Options`,
      options: optionsProp as Option[],
    }
    return [selectedGroup, allOptionsGroup] as Group[]
  }, [isMulti, optionsProp, value])

  return (
    <PopoutSelect
      placeholder={placeholder ?? t`Search...`}
      id={id}
      onChange={onChange}
      onSubmit={onClose}
      resetText={t`Reset`}
      selectAllText={t`Select all`}
      value={value}
      options={memoizedOptions}
      isMulti={isMulti}
      formatSelectedCount={formatSelectedCount}
      closeMenuOnSelect={false}
      noOptionsMessage={noOptionsMessage}
      components={components}
      formatGroupLabel={formatGroupLabel}
      filterOption={filterOption}
      showFooter={showFooter}
      {...props}
    />
  )
}

const formatGroupLabel = (
  data: GroupBase<SelectOptionBase>,
): React.ReactElement => (
  <div>
    <span>
      {data.label} ({data.options.length})
    </span>
  </div>
)
