import { AiInsights, Message } from '@capturi/api-cases'
import { useTrackers } from '@capturi/api-trackers'
import { useScrollable } from '@capturi/react-utils'
import { Colors, Shapes } from '@capturi/sharing'
import { AiContentCard } from '@capturi/ui-components'

import {
  Box,
  Card,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, {
  startTransition,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react'
import { MdDownload, MdUpload } from 'react-icons/md'

import { useFeatureFlags } from '@capturi/feature-flags'
import CaseTrackersView from './CaseTrackersView'
import MessageBubble from './MessageBubble'
import { TrackerShape } from './types'

type Props = {
  messages: Message[]
  messageTypes: {
    Inbound: number
    Outbound: number
    AutoReply: number
  }
  aiInsights: AiInsights | null
  scrollToMessageRef: React.RefObject<HTMLDivElement>
  initialTrackerUid?: string
  messageUidToScrollTo?: string | undefined
}

const Messages: React.FC<Props> = ({
  messages,
  messageTypes,
  aiInsights,
  scrollToMessageRef,
  initialTrackerUid,
  messageUidToScrollTo,
}) => {
  const { aiFeatures } = useFeatureFlags()
  const [selectedTrackerUid, setSelectedTrackerUid] = useState<string | null>(
    initialTrackerUid ?? null,
  )
  const sortedMessages = useMemo(() => {
    return messages.sort((a, b) => a.created.getTime() - b.created.getTime())
  }, [messages])

  const { data: trackers } = useTrackers()

  const trackerMap = useMemo(() => {
    const trackerHits = messages.reduce<Map<string, number>>(
      (memo, message) => {
        message.trackerHits?.forEach((m) => {
          Object.values(m.phrases).map((t) => {
            const initialCount = memo.get(m.trackerUid) || 0
            memo.set(m.trackerUid, t.length + initialCount)
          })
        })
        return memo
      },
      new Map(),
    )

    const trackerMap = new Map(trackers?.map((t) => [t.uid, t.name]))

    return Array.from(trackerHits).reduce<Map<string, TrackerShape>>(
      (memo, [key, value], index) => {
        const trackerName = trackerMap.get(key)
        if (trackerName) {
          memo.set(key, {
            uid: key,
            name: trackerName,
            count: value,
            color: Colors[index % Colors.length],
            shape: Shapes[Math.floor(index / Colors.length) % Shapes.length],
          })
        }
        return memo
      },
      new Map(),
    )
  }, [messages, trackers])

  const trackersList = useMemo(
    () => Array.from(trackerMap, ([_, value]) => value),
    [trackerMap],
  )

  const handleSelectTracker = useCallback(
    (trackerUid: string): void => {
      startTransition(() => {
        if (selectedTrackerUid === trackerUid) {
          setSelectedTrackerUid(null)
        } else {
          setSelectedTrackerUid(trackerUid)
        }
      })
    },
    [selectedTrackerUid],
  )

  const trackerRef = useRef<HTMLDivElement>(null)
  const trackerListHeight = useScrollable(trackerRef, 8)
  const hasTopics = aiInsights?.keyTopic && aiInsights?.keySubTopic

  return (
    <HStack alignItems="flex-start" gap="6">
      <VStack alignItems="stretch" flexGrow="1" gap={0}>
        <Flex flex="1 0 40%">
          {aiFeatures && (
            <Flex flexDir="column" flexGrow="1" gap={0}>
              <Text fontWeight="medium" fontSize="sm" mb={1}>
                <Trans>Capturi AI</Trans>
              </Text>
              <Box mb={4} pos="relative">
                <AiContentCard
                  content={{
                    header: {
                      title: t`Key topic`,
                      topics: hasTopics
                        ? `${aiInsights.keyTopic} > ${aiInsights.keySubTopic}`
                        : t`Details not available.`,
                      subTitle: t`Detailed root cause`,
                    },
                    body: aiInsights?.rootCause ?? t`Details not available.`,
                  }}
                />
              </Box>
            </Flex>
          )}
        </Flex>

        <HStack w="100%" justify="space-between">
          <Text fontSize="sm" fontWeight="medium" mb={1}>
            <Trans>Conversation</Trans>
          </Text>
          <Box
            alignItems="right"
            _focus={{ outline: undefined }}
            _hover={{ bg: 'unset' }}
          >
            <HStack w="100%" gap={2} justify="flex-end">
              <HStack gap={2}>
                <Tooltip label={t`Received`} hasArrow placement="top">
                  <HStack gap="0" align="center">
                    <Icon color="gray.600" boxSize="14px" as={MdDownload} />
                    <Text color="gray.600" fontSize="md">
                      {messageTypes.Inbound}
                    </Text>
                  </HStack>
                </Tooltip>
                <Tooltip label={t`Sent`} hasArrow placement="top">
                  <HStack gap="0" align="center">
                    <Icon color="gray.600" boxSize="14px" as={MdUpload} />
                    <Text color="gray.600" fontSize="md">
                      {messageTypes.Outbound + messageTypes.AutoReply}
                    </Text>
                  </HStack>
                </Tooltip>
              </HStack>
            </HStack>
          </Box>
        </HStack>
        <Card
          boxShadow="none"
          border="1px solid"
          borderColor="gray.200"
          flexGrow="1"
        >
          <Flex
            px={3}
            py={2}
            flexDirection="column"
            bg="accents.lightBackground.default"
          >
            {sortedMessages.map((m) => (
              <MessageBubble
                key={m.uid}
                trackersMap={trackerMap}
                selectedTrackerUid={selectedTrackerUid}
                message={m}
                scrollToMessageRef={
                  messageUidToScrollTo === m.uid ? scrollToMessageRef : null
                }
              />
            ))}
          </Flex>
        </Card>
      </VStack>
      <Box
        w="230px"
        flexShrink={0}
        position="sticky"
        top="0"
        ref={trackerRef}
        h={trackerListHeight}
        overflowY="auto"
      >
        <CaseTrackersView
          trackerList={trackersList}
          selectedTrackerUid={selectedTrackerUid}
          onSelectTracker={handleSelectTracker}
        />
      </Box>
    </HStack>
  )
}

export default Messages
