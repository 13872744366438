import { useSet } from '@capturi/react-utils'
import request, { ResponseError } from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import ufuzzy from '@leeoniya/ufuzzy'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { Channel, channelToType } from '../../types'

type KeyTopicsResponse = { values: string[] }

const uf = new ufuzzy({ intraMode: 1, intraIns: 10, alpha: 'a-zæøåöä' })

export const useKeyTopicsFilterValuesData = (
  channel: Channel,
): UseQueryResult<string[], ResponseError> => {
  const { conversationLanguage } = useOrganization()
  const compare = useMemo(
    () => Intl.Collator(conversationLanguage).compare,
    [conversationLanguage],
  )

  return useQuery({
    queryKey: ['keyTopic', 'filter-values', channel],
    queryFn: () =>
      request.get<KeyTopicsResponse>(
        `organization/${channelToType(channel)}/filter-values/KeyTopic`,
        {
          query: {
            'api-version': '3.3',
          },
        },
      ),
    select: (response) => response.values.sort(compare),
    staleTime: 60 * 60 * 24, // 1 day
  })
}
const useKeyTopicsOfflineSearch = (searchTerm: string, channel: Channel) => {
  const { data, isLoading } = useKeyTopicsFilterValuesData(channel)
  const keyTopics = data ?? []
  if (searchTerm === '') return { keyTopics, isLoading }

  const idxs = uf.filter(keyTopics, searchTerm)
  if (idxs === null || idxs.length === 0) return { keyTopics: [], isLoading }

  const filteredKeyTopics = idxs.map((idx) => keyTopics[idx])
  return { keyTopics: filteredKeyTopics, isLoading }
}

export const useKeyTopicsFilterValues = (
  initiallySelectedKeyTopics: string[] | null,
  searchTerm: string,
  channel: Channel,
): {
  isLoading: boolean
  filteredKeyTopics: string[]
  selectedKeyTopics: string[]
  isSelected: (keyTopic: string) => boolean
  selectedSize: number
  predefinedKeyTopics: string[] | undefined
  toggleSelection: (keyTopic: string) => void
  resetSelection: () => void
  selectAll?: () => void
} => {
  const { isLoading, keyTopics: filteredKeyTopics } = useKeyTopicsOfflineSearch(
    searchTerm,
    channel,
  )

  const { data: allPredefinedKeyTopics } = usePredefinedKeyTopics()
  const predefinedKeyTopics = useMemo(() => {
    if (!allPredefinedKeyTopics) return undefined
    if (!searchTerm) return allPredefinedKeyTopics

    const idxs = uf.filter(allPredefinedKeyTopics, searchTerm)
    return idxs ? idxs.map((i) => allPredefinedKeyTopics[i]) : []
  }, [allPredefinedKeyTopics, searchTerm])

  const selected = useSet(initiallySelectedKeyTopics || [])

  return useMemo(() => {
    const filteredKeyTopicsWithoutPredefined = filteredKeyTopics.filter(
      (topic) => !predefinedKeyTopics?.includes(topic),
    )

    const toggleSelection = (keyTopic: string) => {
      selected.has(keyTopic)
        ? selected.delete(keyTopic)
        : selected.add(keyTopic)
    }

    const resetSelection = () => {
      selected.clear()
    }

    const selectAll = () => {
      let allVisibleKeyTopics: string[] = []

      if (searchTerm === '') {
        // When no search term, only select predefined topics
        allVisibleKeyTopics = predefinedKeyTopics ?? []
      } else {
        // During search, select filtered topics and append predefined ones if they exist
        allVisibleKeyTopics = predefinedKeyTopics
          ? [...filteredKeyTopicsWithoutPredefined, ...predefinedKeyTopics]
          : filteredKeyTopicsWithoutPredefined
      }

      selected.bulkAdd(allVisibleKeyTopics)
    }

    const selectedKeyTopics = selected.toArray()
    const filteredSelectedKeyTopics = predefinedKeyTopics
      ? selectedKeyTopics.filter(
          (topic) => !predefinedKeyTopics.includes(topic),
        )
      : selectedKeyTopics

    if (searchTerm === '') {
      return {
        isLoading,
        filteredKeyTopics: filteredSelectedKeyTopics,
        selectedKeyTopics,
        predefinedKeyTopics,
        isSelected: (keyTopic: string) => selected.has(keyTopic),
        selectedSize: selected.size,
        toggleSelection,
        resetSelection,
        selectAll,
      }
    }
    return {
      isLoading,
      filteredKeyTopics: filteredKeyTopicsWithoutPredefined,
      selectedKeyTopics,
      predefinedKeyTopics,
      isSelected: (keyTopic: string) => selected.has(keyTopic),
      selectedSize: selected.size,
      toggleSelection,
      resetSelection,
      selectAll,
    }
  }, [isLoading, filteredKeyTopics, selected, searchTerm, predefinedKeyTopics])
}

export const usePredefinedKeyTopics = (): UseQueryResult<
  string[],
  ResponseError
> => {
  const { conversationLanguage } = useOrganization()
  const compare = useMemo(
    () => Intl.Collator(conversationLanguage).compare,
    [conversationLanguage],
  )

  return useQuery({
    queryKey: ['predefinedKeyTopics'],
    queryFn: () =>
      request.get<{ keyTopics: string[] }>(
        'organization/predefined-key-topics',
        {
          query: {
            'api-version': '3.3',
          },
        },
      ),
    select: (response) => response.keyTopics.sort(compare),
    staleTime: 60 * 60 * 24, // 1 day
  })
}
