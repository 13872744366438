import { Button } from '@capturi/ui-components'
import { BaseModalComponentProps, useModal } from '@capturi/use-modal'
import { HStack, ModalFooter, Text, Tooltip, VStack } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import DeleteSubscriptionConfirmModal from 'pages/SubscriptionsPage/components/DeleteSubscriptionConfirmModal'
import React from 'react'

type Props = {
  isDisabled: boolean
  isLoading: boolean
  createdByUsername?: string
  updatedByUsername?: string
  createdDate?: Date
  updatedDate?: Date
  onCreateSubscription: () => void
  subscriptionUid?: string
  isScheduleDaysEmpty: boolean
} & BaseModalComponentProps

const SubscriptionFooter: React.FC<Props> = ({
  onClose,
  isDisabled,
  isLoading,
  createdByUsername,
  updatedByUsername,
  createdDate,
  updatedDate,
  onCreateSubscription,
  subscriptionUid,
  isScheduleDaysEmpty,
}) => {
  const [openDeleteSubscriptionModal] = useModal(DeleteSubscriptionConfirmModal)
  return (
    <ModalFooter justifyContent="space-between">
      <VStack gap="0" align="flex-start">
        {createdDate && (
          <Text fontSize="xs" color="gray.600">
            <Trans>Created by </Trans> {createdByUsername},{' '}
            {i18n.date(createdDate, { dateStyle: 'long' })}
          </Text>
        )}
        {updatedDate && (
          <Text fontSize="xs" color="gray.600">
            <Trans>Updated by</Trans> {updatedByUsername},{' '}
            {i18n.date(updatedDate, { dateStyle: 'long' })}
          </Text>
        )}
      </VStack>
      <HStack>
        {subscriptionUid && (
          <Button
            mr={3}
            colorScheme="red"
            onClick={() =>
              openDeleteSubscriptionModal({
                subscriptionUid: subscriptionUid,
              })
            }
          >
            <Trans>Delete</Trans>
          </Button>
        )}
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Tooltip
          label={
            isScheduleDaysEmpty
              ? t`Minimum 1 day must be selected under 'recurrence'.`
              : t`Name and at least one recipient is required.`
          }
          hasArrow
          placement="top"
          isDisabled={!isDisabled && !isScheduleDaysEmpty}
        >
          <Button
            isDisabled={isDisabled || isScheduleDaysEmpty}
            isLoading={isLoading}
            primary
            onClick={() => onCreateSubscription()}
          >
            <Trans>Save</Trans>
          </Button>
        </Tooltip>
      </HStack>
    </ModalFooter>
  )
}

export default SubscriptionFooter
