import analytics from '@capturi/analytics'
import request from '@capturi/request'
import { useToast } from '@capturi/ui-components'
import { useConfirm } from '@capturi/use-confirm'
import { useModal } from '@capturi/use-modal'
import { Text } from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { I18nProvider } from '@lingui/react'
import { routes } from 'pages/Library'
import React from 'react'
import { useNavigate } from 'react-router'

import { LibraryAnalyticsEvent, logEvent } from './analytics'
import { api as apiFactory } from './api'
import { CreateSnippetDialog } from './components/CreateSnippetDialog'
import { UpdateSnippetDialog } from './components/UpdateSnippetDialog'
import { LibraryFolder, LibraryItem, NewFolder, NewSnippet } from './types'
import { folderUtils } from './utils'

const api = apiFactory()

type UseAudioSnippetCRUDOptions = {
  onSnippetDeleted?: (audioSnippet: LibraryItem) => void
  onSnippetUpdated?: (audioSnippet: LibraryItem) => void
  onSnippetCreated?: (audioSnippet: LibraryItem) => void
  onSnippetsDeleted?: (audioSnippets: LibraryItem[]) => void
}

type UseAudioSnippetCRUD = {
  deleteSnippet: (snippet: LibraryItem) => void
  deleteAllSnippets: (snippets: LibraryItem[]) => Promise<void>
  editSnippet: (snippet: LibraryItem) => void
  createSnippet: (
    conversationUid: string,
    timestamp: number,
    audioDuration: number,
    initialTitle?: string,
    onClose?: () => void,
    onCancel?: () => void,
  ) => void
}
function CreateAudioSnippetToastDescription(
  url: string,
  onNavigate: () => void,
): JSX.Element {
  return (
    <I18nProvider i18n={i18n}>
      <Text pr="1" display="inline">
        <Trans>
          Go to
          <Text
            as="a"
            href={url}
            cursor="pointer"
            ml="1"
            textDecor="underline"
            onClick={(e) => {
              e.preventDefault()
              onNavigate()
            }}
          >
            library page for snippet
          </Text>
        </Trans>
      </Text>
    </I18nProvider>
  )
}

function useAudioSnippetCRUD(
  options: UseAudioSnippetCRUDOptions = {},
): UseAudioSnippetCRUD {
  const toast = useToast()

  const confirm = useConfirm()
  const navigate = useNavigate()

  const [openEditSnippetDialog] = useModal(UpdateSnippetDialog)
  const [openCreateSnippetDialog] = useModal(CreateSnippetDialog)

  const {
    onSnippetCreated,
    onSnippetUpdated,
    onSnippetDeleted,
    onSnippetsDeleted,
  } = options

  const handleDeleteSnippet = React.useCallback(
    async (snippet: LibraryItem): Promise<void> => {
      try {
        await request.delete(...api.deleteSnippet(snippet.uid))
        onSnippetDeleted?.(snippet)

        toast({
          title: t`Audio snippet deleted`,
          description: t`The audio snippet ‘${snippet.title}‘ has been deleted`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The audio snippet ‘${snippet.title}‘ was not deleted`,
          status: 'error',
        })
      }
    },
    [onSnippetDeleted, toast],
  )
  const handleDeleteAllSnippets = React.useCallback(
    async (snippets: LibraryItem[]): Promise<void> => {
      try {
        const deletingPromises = snippets.map((snip) =>
          request.delete(...api.deleteSnippet(snip.uid)),
        )

        await Promise.all(deletingPromises)

        onSnippetsDeleted?.(snippets)
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`Some audio snippets were not deleted`,
          status: 'error',
        })
      }
    },
    [onSnippetsDeleted, toast],
  )

  const handleUpdateSnippet = React.useCallback(
    async (
      snippet: LibraryItem,
      newFolder: NewFolder | undefined,
    ): Promise<void> => {
      try {
        let folderUid: string | undefined = snippet.folderUid
        if (newFolder) {
          const newPlaylist: LibraryFolder = await request.post(
            ...api.createFolder(newFolder),
          )
          folderUid = newPlaylist.uid
        }

        const { title, description } = snippet
        await request.patch(
          ...api.updateSnippet(snippet.uid, title, description, folderUid),
        )
        onSnippetUpdated?.(snippet)
        toast({
          title: t`Audio snippet updated`,
          description: t`The audio snippet ‘${snippet.title}‘ has been updated`,
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`An error occurred`,
          description: t`The audio snippet ‘${snippet.title}‘ was not updated`,
          status: 'error',
        })
      }
    },
    [onSnippetUpdated, toast],
  )

  const handleCreateSnippet = React.useCallback(
    async (
      snippet: NewSnippet,
      newFolder: NewFolder | undefined,
      sendNotifications: boolean,
    ) => {
      try {
        let folderUid: string | undefined = snippet.folderUid
        if (newFolder) {
          const newPlaylist: LibraryFolder = await request.post(
            ...api.createFolder(newFolder),
          )
          folderUid = newPlaylist.uid
        }

        const { title, description, from, to, conversationUid } = snippet
        const newSnippet: LibraryItem = await request.post(
          ...api.createSnippet({
            snippet: {
              conversationUid,
              description,
              from,
              title,
              to,
              folderUid,
            },
            sendOutOfAppNotification: sendNotifications,
            sendInAppNotification: sendNotifications,
          }),
        )
        onSnippetCreated?.(newSnippet)
        toast({
          title: t`Audio snippet created`,
          description: CreateAudioSnippetToastDescription(
            routes.folder(folderUid),
            () => {
              analytics.event('audio_snippet_created_url_clicked')
              toast.closeAll()
              navigate(routes.folder(folderUid))
            },
          ),
          status: 'success',
        })
      } catch (_error) {
        toast({
          title: t`The library snippet could not be saved.`,
          description: t`The maximum duration of a snippet is 25 min.`,
          status: 'error',
        })
      }
    },
    [navigate, onSnippetCreated, toast],
  )
  return React.useMemo(() => {
    return {
      deleteSnippet: async (snippet: LibraryItem) => {
        try {
          // Open confirm dialog
          await confirm({
            title: t`Delete audio snippet`,
            description: t`Are you sure want to delete the audio snippet "${snippet.title}"?`,
            cancelText: t`Cancel`,
            confirmText: t`Delete`,
          })

          // Delete snippet
          logEvent(LibraryAnalyticsEvent.DeleteSnippet)
          handleDeleteSnippet(snippet)
        } catch {
          // cancelled
        }
      },
      deleteAllSnippets: async (snippets: LibraryItem[]) => {
        try {
          // Delete All snippets
          await handleDeleteAllSnippets(snippets)
          logEvent(LibraryAnalyticsEvent.DeleteAllSnippets)
        } catch {
          // cancelled
        }
      },
      editSnippet: (snippet: LibraryItem) => {
        // Open edit dialog
        openEditSnippetDialog({
          snippet,
          onSubmit: ({ snippet, folder, originalSnippet }) => {
            logEvent(LibraryAnalyticsEvent.UpdateSnippet, {
              folderChanged:
                folder != null ||
                snippet.folderUid !== originalSnippet?.folderUid,
              folderIsNew: folder != null,
            })
            if (folder != null) {
              logEvent(LibraryAnalyticsEvent.CreatePlaylistFromSnippetDialog, {
                isPublic: folderUtils.isPublic(folder.permissions),
                roles: folder.permissions?.roles,
                userCount: folder.permissions?.users?.length ?? 0,
              })
            }
            // Save updated snippet
            handleUpdateSnippet(snippet, folder)
          },
        })
      },
      createSnippet: (
        conversationUid: string,
        timestamp: number,
        audioDuration: number,
        initialTitle?: string,
        onClose?: () => void,
      ) => {
        // Open create dialog
        openCreateSnippetDialog({
          conversationUid,
          timestamp,
          audioDuration,
          initialTitle,
          onSubmit: ({ snippet, folder, sendNotifications }) => {
            logEvent(LibraryAnalyticsEvent.CreateSnippet, {
              folderIsNew: folder != null,
              timestamp: snippet.from,
              duration: snippet.to - snippet.from,
              relativePosition: snippet.from / audioDuration,
              sendOutOfAppNotification: false,
              conversationId: snippet.conversationUid,
              folderUid: snippet.folderUid,
            })
            if (folder != null) {
              logEvent(LibraryAnalyticsEvent.CreatePlaylistFromSnippetDialog, {
                isPublic: folderUtils.isPublic(folder.permissions),
                roles: folder.permissions?.roles,
                userCount: folder.permissions?.users?.length ?? 0,
              })
            }
            snippet.to = Math.min(snippet.to, audioDuration)
            handleCreateSnippet(snippet, folder, sendNotifications)
            onClose?.()
          },
          onClose,
        })
      },
    }
  }, [
    confirm,
    handleDeleteSnippet,
    handleDeleteAllSnippets,
    openEditSnippetDialog,
    handleUpdateSnippet,
    openCreateSnippetDialog,
    handleCreateSnippet,
  ])
}

export default useAudioSnippetCRUD
