import analytics from '@capturi/analytics'
import {
  Box,
  Button,
  Card,
  CardBody,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  Flex,
  List,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import React, { useCallback, useMemo, useState } from 'react'

import { WordCloudItem } from '@capturi/charts'
import { Select, SelectOption } from '@capturi/ui-select'
import { useTimeout } from 'react-use'
import { useKeyTopicConversations } from '../../../pages/KeyTopics/hooks/useKeyTopicConversations'
import { DrawerBenchmarksSkeleton } from '../Benchmarks/DrawerBenchmark/DrawerBenchmarks'
import { TextDrawerBenchmarks } from '../Benchmarks/DrawerBenchmark/TextDrawerBenchmarks'
import { DrawerWordCloud } from './DrawerWordCloud'
import { TextCaseListItem } from './TextCaseListItem'

type KeyTopicOptions = SelectOption & {
  amount: number
}

type TextKeyTopicsDrawerProps = {
  sessionUid: string
  topicId: string
  subTopicId?: string
  clusterName: string
  topics?: { name: string; amount: number }[]
  selectedWordCloudItem?: string
} & Omit<DrawerProps, 'children'>

export const TextKeyTopicsDrawer: React.FC<TextKeyTopicsDrawerProps> = ({
  isOpen,
  onClose,
  sessionUid,
  topicId,
  subTopicId,
  clusterName,
  topics,
  selectedWordCloudItem,
}) => {
  const [highlightedWordCloudItem, setHighlightedWordCloudItem] = useState<
    string | undefined
  >(selectedWordCloudItem)
  const handleSelectWordCloudItem = useCallback(
    (item: WordCloudItem | undefined) => {
      setHighlightedWordCloudItem(item?.name)
      analytics.event('text_key_topics_drawer_wordcloud_selected')
    },
    [],
  )
  const keyTopicOptions = useMemo(() => {
    return (topics ?? [])
      .filter((kt) => kt.amount !== 0)
      .map<KeyTopicOptions>((x) => ({
        value: x.name,
        label: `${x.name} (${x.amount.toString()})`,
        amount: x.amount,
      }))
  }, [topics])

  const [selectedTopics, setSelectedTopics] = useState<KeyTopicOptions[]>([])
  const { data, isLoading, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useKeyTopicConversations({
      sessionUid: sessionUid,
      keyTopicClusterId: topicId,
      keySubTopicClusterId: subTopicId,
      body: {
        pageSize: 50,
        keyTopics: selectedTopics.map((kt) => kt.value),
        phrases: null, // no “rapid search”
        ngrams: highlightedWordCloudItem
          ? [highlightedWordCloudItem]
          : undefined,
        onlyRepeatCalls: false,
      },
      sleepBeforeResolving: true,
    })

  const conversations = useMemo(() => {
    return (data?.pages ?? []).flatMap((page) => page.conversations)
  }, [data])

  const loading = isLoading || !data?.pages?.[0]

  // For the single cluster’s partial benchmark (the first page has benchmark info)
  const conversationCount = data?.pages?.[0]?.conversations.length ?? 0
  const conversationsPct = data?.pages?.[0]?.percentage ?? 0
  const percentageOfTotal = data?.pages?.[0]?.percentageOfTotal ?? 0

  const wordCloud = data?.pages?.[0]?.wordCloud
  const trendWordCloud = data?.pages?.[0]?.trendWordCloud

  const [isReady] = useTimeout(600)
  if (!isReady) {
    return (
      <Flex flexWrap="wrap" gap={2}>
        <DrawerBenchmarksSkeleton />
      </Flex>
    )
  }

  return (
    <Drawer isOpen={isOpen} onClose={onClose} placement="right" size="xl">
      <DrawerOverlay />
      <DrawerContent maxW="1000px">
        <DrawerCloseButton right={2} top={3} w={8} h={8} />
        <DrawerHeader
          borderBottom="1px"
          borderBottomColor="gray.200"
          lineHeight={1.4}
          display="flex"
          flexDir="column"
          px={3}
          fontFamily="body"
          fontWeight="normal"
        >
          <Text fontSize="md" fontWeight="medium" textTransform="uppercase">
            {clusterName}
          </Text>

          <Text
            fontSize="xs"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            textColor="gray.600"
            w="100%"
          >
            {keyTopicOptions
              .slice(
                1,
                5,
              ) /* Skip first topic and stop at the fifth, as it's in label */
              .map((topic) => topic.value)
              .join(', ')}
            {keyTopicOptions.length > 5 && <Trans>, etc.</Trans>}
          </Text>
        </DrawerHeader>

        <DrawerBody
          overflow="hidden"
          px={6}
          display="flex"
          flexDir="column"
          gap={4}
          pt={4}
          pb={0}
        >
          <Box w="30%">
            <Select
              isDisabled={false}
              options={keyTopicOptions}
              onChange={(e) => {
                setSelectedTopics([...e])
                analytics.event('key_topics_select_topics_in_drawer')
              }}
              isMulti={true}
              noOptionsMessage={() => t`All key topics selected`}
              placeholder={t`Select topics...`}
              isSearchable={false}
              menuPortalTarget={document.body}
              menuPosition="fixed"
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  minHeight: '2.5rem',
                  overflow: 'auto',
                  fontFamily: 'Rubik, sans-serif',
                }),
              }}
            />
          </Box>
          <Flex flex={1} overflow="hidden" gap={4}>
            {/* Column #1: benchmark + word cloud */}
            <VStack
              alignItems="stretch"
              w="55%"
              display="flex"
              flexDirection="column"
              gap={4}
            >
              {/* If still loading skeleton or not */}
              {loading ? (
                <Flex flexWrap="wrap" gap={2}>
                  <Skeleton
                    borderRadius="md"
                    minH="11rem"
                    shadow="none"
                    border="1px solid"
                    borderColor="gray.200"
                    w="100%"
                  />
                </Flex>
              ) : (
                // Minimal “Conversations” benchmark
                <TextDrawerBenchmarks
                  conversationCount={conversationCount}
                  conversationsPct={conversationsPct}
                  percentageOfTotal={percentageOfTotal}
                />
              )}

              {/* Word cloud */}
              <Card
                shadow="none"
                border="1px solid"
                borderColor="gray.200"
                flex={1}
              >
                <CardBody px={0} py={3}>
                  <Box h="100%">
                    {!loading && (
                      <DrawerWordCloud
                        wordCloud={wordCloud}
                        trendWordCloud={trendWordCloud}
                        selectedItemName={highlightedWordCloudItem}
                        onSelectItem={handleSelectWordCloudItem}
                      />
                    )}
                  </Box>
                </CardBody>
              </Card>
            </VStack>

            <Box h="100%" w="1px" bg="gray.200" />

            {/* Column #2: conversations list */}
            <List spacing={4} w="45%" overflow="auto">
              {conversations.map((conversation) => (
                <TextCaseListItem
                  key={conversation.uid}
                  conversation={conversation}
                />
              ))}

              <Flex my={4} justify="center">
                <Button
                  onClick={() => {
                    fetchNextPage()
                    analytics.event('text_keyTopics_load_more')
                  }}
                  isDisabled={!hasNextPage || isFetchingNextPage}
                  isLoading={isFetchingNextPage}
                >
                  {hasNextPage ? (
                    <Trans>Load more</Trans>
                  ) : (
                    <Trans>No more conversations</Trans>
                  )}
                </Button>
              </Flex>
            </List>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
