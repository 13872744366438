import ufuzzy from '@leeoniya/ufuzzy'
import { useMemo } from 'react'
type Org = { name: string; id: string }

const uf = new ufuzzy({ intraMode: 1, intraIns: 10, alpha: 'a-zæøåöä' })

const sort = Intl.Collator('da-DK').compare

export const useFilteredOrgs = (orgs: Org[], searchTerm: string): Org[] => {
  const sortedOrgs = useMemo(
    () => orgs.sort((a, b) => sort(a.name, b.name)),
    [orgs],
  )
  const orgNames = useMemo(() => orgs.map((o) => o.name), [orgs])

  if (searchTerm === '') return sortedOrgs

  const idxs = uf.filter(orgNames, searchTerm)
  //IF there isn't any matches
  if (idxs === null || idxs.length === 0) return []

  const info = uf.info(idxs, orgNames, searchTerm)

  // order is a double-indirection array (a re-order of the passed-in idxs)
  // this allows corresponding info to be grabbed directly by idx, if needed
  const order = uf.sort(info, orgNames, searchTerm)

  // using info.idx here instead of idxs because uf.info() may have
  // further reduced the initial idxs based on prefix/suffix rules
  return order.map((o) => orgs[info.idx[order[o]]])
}
