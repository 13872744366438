import { Button } from '@capturi/ui-components'
import { useModal } from '@capturi/use-modal'
import { Card, CardBody, Text } from '@chakra-ui/react'
import { FC } from 'react'
import CreateUpdateOrganization from '../shared/CreateUpdateOrganization'
import { type Masterdata } from '../shared/useMasterdata'
import { useSingleOrganization } from '../shared/useOrganizations'
import { ConfirmMarkAsProvisionedModal } from './ConfirmMarkAsProvisionedModal'

type Props = { provision: Masterdata }

export const CreateCard: FC<Props> = ({ provision }) => {
  const { data: existingOrg } = useSingleOrganization(provision.externalId)

  const [showCreateUpdateOrgModal] = useModal(CreateUpdateOrganization)
  const [showConfirmMarkAsProvisionedModal] = useModal(
    ConfirmMarkAsProvisionedModal,
  )

  return (
    <Card key={provision.id} my="4">
      <CardBody
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <div>
          <Text color="gray.600" fontStyle="italic">
            {provision.country}
          </Text>
          <Text>
            {provision.name} - {provision.description}
          </Text>

          <Text color="gray.600">{provision.customer?.name}</Text>
        </div>
        {existingOrg ? (
          <Button
            colorScheme="primary"
            onClick={() =>
              showConfirmMarkAsProvisionedModal({
                organizationName: existingOrg.name,
                provisionId: provision.id,
                type: 'created',
              })
            }
          >
            Mark as created
          </Button>
        ) : (
          <Button
            colorScheme="green"
            onClick={() =>
              showCreateUpdateOrgModal({
                provisionId: provision.id,
                uid: provision.externalId,
                name: `${provision.name} (${provision.customer?.name})`,
              })
            }
          >
            Create
          </Button>
        )}
      </CardBody>
    </Card>
  )
}
