import { Summary, useSummaries } from '@capturi/api-summaries'
import {
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { ResponseError } from '@capturi/request'
import { useAverageDuration } from 'pages/HomePage/hooks/useAverageDuration'
import { useMemo } from 'react'

//Predefined list of emojis
const emojis = ['⏰', '⌛', '⏲️', '🛎️', '⏱️', '⌚️', '🕰️']

type SummariesWithSentimentData = {
  isLoading: boolean
  error: ResponseError | null
  data: WidgetSummary[]
}

export type WidgetSummary = Summary & { emoji: string }

export function useLongConversationExamples(): SummariesWithSentimentData {
  const segmentState = useFirstPhoneSegmentState()
  const { period } = useFilterPeriodContext()

  const { data: averageDuration } = useAverageDuration()
  const hasAverageDuration = !!averageDuration && averageDuration > 0

  const {
    data: summaries,
    isLoading: areSummariesLoading,
    error,
  } = useSummaries({
    filterValues: segmentState.values
      ? {
          ...segmentState.values,
          duration: { min: (averageDuration ?? 0) * 2 },
        }
      : segmentState.values,
    period,
    pageLimit: 40,
    enabled: hasAverageDuration,
  })
  const mappedSentimentSummaries = useMemo(() => {
    if (!summaries?.pages.length) {
      return []
    }
    const data = summaries.pages[0]
      .reduce<WidgetSummary[]>((acc, summary) => {
        if (summary.rootCause === null) {
          return acc
        }

        acc.push({
          ...summary,
          emoji: emojis[Math.floor(Math.random() * emojis.length)],
        })
        return acc
      }, [])
      .sort((a, b) => a.dateTime.getDate() - b.dateTime.getDate())
    return data
  }, [summaries?.pages[0]])

  return {
    isLoading: areSummariesLoading,
    error,
    data: mappedSentimentSummaries,
  }
}
