import analytics from '@capturi/analytics'
import { PlaySnippetsButton } from '@capturi/ui-components'

import {
  Box,
  Checkbox,
  Divider,
  Flex,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import { i18n } from '@lingui/core'
import { Trans, t } from '@lingui/macro'
import { FC, memo, useCallback } from 'react'
import { MdArrowDropDown, MdArrowDropUp, MdCampaign } from 'react-icons/md'
import { useEffectOnce } from 'react-use'

import { pctFormat } from '../analytics/shared/utils'
import { Benchmark, Topic, TopicsNode } from './hooks/types'

export type TableMode = 'normal' | 'selection'

const TopicsRowContent: FC<{
  label: string
  color: string | undefined
  amount: number
  percentage: number
  percentageOfTotal?: number
  amountVsExpectedDiff: number
  significant: boolean
  topicId: string
  subTopicId?: string
  sessionUid: string
  referenceBenchmark: Benchmark
  topics?: Topic[]
  showCheckbox: boolean
  showPlayButton: boolean
  isRowSelected: boolean
  showTopicsSubtitle: boolean
  openKeyTopicsDrawer: (
    node: {
      topicId: string
      subTopicId?: string
      label: string
      topics?: {
        name: string
        amount: number
      }[]
    },
    referenceBenchmark?: Benchmark,
  ) => void
}> = ({
  label,
  color,
  amount,
  percentage,
  percentageOfTotal,
  amountVsExpectedDiff,
  significant,
  topicId,
  subTopicId,
  sessionUid,
  referenceBenchmark,
  topics,
  showCheckbox,
  showPlayButton,
  isRowSelected,
  showTopicsSubtitle,
  openKeyTopicsDrawer,
}) => {
  return (
    <>
      {showCheckbox && (
        <Td w="5%">
          <Checkbox isChecked={isRowSelected} pointerEvents="none" />
        </Td>
      )}
      <Td w="38%">
        <Flex alignItems="flex-start" gap={2}>
          {color && (
            <Box
              id="color-square"
              backgroundColor={color}
              borderRadius="sm"
              w={2}
              h={2}
              mt="6px"
              flex="0 0 auto"
            />
          )}
          <Flex alignItems="flex-start" direction="column" gap={0}>
            <Text id="first-line" noOfLines={1}>
              {label}
            </Text>

            {showTopicsSubtitle && (
              <Text noOfLines={1} fontSize="xs" textColor="gray.500">
                {topics
                  ?.filter((t) => t.amount !== 0)
                  .slice(1) /* Skip first topic, as it's in label */
                  .map((topic) => topic.name)
                  .join(', ')}
              </Text>
            )}
          </Flex>
        </Flex>
      </Td>
      <Td w="17%">{amount}</Td>
      <Td w="15%">
        <Flex>
          <Box>
            <Text>{i18n.number(percentage, pctFormat)}</Text>
          </Box>
          {percentageOfTotal !== undefined && (
            <Box ml={0.5}>
              <Text fontSize="xs" textColor="gray.600">
                {`(${i18n.number(percentageOfTotal, pctFormat)})`}
              </Text>
            </Box>
          )}
        </Flex>
      </Td>
      <Td w="19%">
        <Flex alignItems="center">
          {amountVsExpectedDiff > 0 ? <Icon as={MdArrowDropUp} /> : null}
          {amountVsExpectedDiff < 0 ? <Icon as={MdArrowDropDown} /> : null}
          {amountVsExpectedDiff === 0 ? <Box w="14px" /> : null}
          <Text color="gray.600">{Math.abs(amountVsExpectedDiff)}</Text>
          {significant && <Icon as={MdCampaign} ml={1} />}
        </Flex>
      </Td>
      {showPlayButton && (
        <Td w="7%" px="2">
          <PlaySnippetsButton
            onClick={(e) => {
              e.stopPropagation()
              openKeyTopicsDrawer(
                {
                  topicId,
                  subTopicId,
                  topics,
                  label,
                },
                referenceBenchmark,
              )

              analytics.event('key_topics_open_drawer', {
                topicId,
                subTopicId,
                sessionUid,
                clusterName: label,
              })
            }}
            label={t`Discover`}
          />
        </Td>
      )}
    </>
  )
}

const TopicsRowContentMemoized = memo(TopicsRowContent)

const TopicsRow: FC<{
  item: TopicsNode
  color: string | undefined
  sessionUid: string
  referenceBenchmark: Benchmark
  showCheckbox: boolean
  showPlayButton: boolean
  isRowSelected: boolean
  selectNode: ((node: TopicsNode) => void) | undefined
  highlighted: boolean
  setHoveredNodeId: (id: string | null) => void
  showTopicsSubtitle: boolean
  openKeyTopicsDrawer: (
    node: {
      topicId: string
      subTopicId?: string
      label: string
      topics?: {
        name: string
        amount: number
      }[]
    },
    referenceBenchmark?: Benchmark,
  ) => void
}> = ({
  item,
  color,
  sessionUid,
  referenceBenchmark,
  showCheckbox,
  showPlayButton,
  isRowSelected,
  selectNode,
  highlighted,
  setHoveredNodeId,
  showTopicsSubtitle,
  openKeyTopicsDrawer,
}) => {
  const handleOnClick = useCallback(() => {
    selectNode?.(item)
  }, [item, selectNode])

  const handleOnMouseEnter = useCallback(() => {
    setHoveredNodeId(item.id)
  }, [item.id, setHoveredNodeId])

  const handleOnMouseLeave = useCallback(() => {
    setHoveredNodeId(null)
  }, [setHoveredNodeId])

  return (
    <Tr
      key={item.topicId}
      bg={highlighted ? 'gray.100' : ''}
      onClick={handleOnClick}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      cursor={selectNode ? 'pointer' : undefined}
    >
      <TopicsRowContentMemoized
        {...item}
        color={color}
        showCheckbox={showCheckbox}
        showPlayButton={showPlayButton}
        isRowSelected={isRowSelected}
        sessionUid={sessionUid}
        referenceBenchmark={referenceBenchmark}
        showTopicsSubtitle={showTopicsSubtitle}
        openKeyTopicsDrawer={openKeyTopicsDrawer}
      />
    </Tr>
  )
}

const TopicsRowMemoized = memo(TopicsRow)

export const TopicsTable: FC<{
  rows: TopicsNode[] | undefined
  otherRow: TopicsNode | undefined
  colors: string[]
  sessionUid: string
  referenceBenchmark: Benchmark
  isNodeSelected: (node: TopicsNode) => boolean
  selectNode: (node: TopicsNode) => void
  hoveredNodeId: string | null
  setHoveredNodeId: (id: string | null) => void
  localizedNodeEntityName: string
  mode: TableMode
  showTopicsSubtitle: boolean
  openKeyTopicsDrawer: (
    node: {
      topicId: string
      subTopicId?: string
      label: string
      topics?: {
        name: string
        amount: number
      }[]
    },
    referenceBenchmark?: Benchmark,
  ) => void
}> = memo(
  ({
    rows,
    otherRow,
    colors,
    sessionUid,
    referenceBenchmark,
    isNodeSelected,
    selectNode,
    hoveredNodeId,
    setHoveredNodeId,
    localizedNodeEntityName,
    mode,
    showTopicsSubtitle,
    openKeyTopicsDrawer,
  }) => {
    const isEmpty = rows == null || (rows.length === 0 && otherRow == null)
    const hasPercentageOfTotal = rows?.some(
      (row) => row.percentageOfTotal !== undefined,
    )

    useEffectOnce(() => {
      analytics.event('key_topics_loaded', {
        sessionUid,
      })
    })

    return (
      <Flex direction="column" overflowY="hidden">
        <Box
          overflowY="auto"
          shadow="base"
          borderRadius="0.25rem"
          css={css`
    scrollbar-width: none;
  `}
        >
          <Table w="100%" variant="key-topics">
            <Thead>
              <Tr>
                {mode === 'selection' && <Th w="5%" />}
                <Th
                  fontWeight="medium"
                  w={mode === 'selection' ? '33%' : '38%'}
                >
                  {localizedNodeEntityName}
                </Th>
                <Th isNumeric fontWeight="medium" w="17%">
                  <Trans>Conversations</Trans>
                </Th>
                <Th isNumeric fontWeight="medium" w="15%">
                  %
                  {hasPercentageOfTotal && (
                    <span style={{ whiteSpace: 'pre' }}>
                      <Trans>(% of total)</Trans>
                    </span>
                  )}
                </Th>
                <Th isNumeric fontWeight="medium" w="19%">
                  <Trans>vs. Expected</Trans>
                </Th>
                {mode === 'normal' && <Th w="7%" />}
              </Tr>
            </Thead>
            <Tbody>
              {isEmpty ? (
                <Tr>
                  <Td colSpan={mode === 'selection' ? 6 : 5}>
                    <Text align="center">
                      <Trans>No data available</Trans>
                    </Text>
                  </Td>
                </Tr>
              ) : (
                <>
                  {rows &&
                    rows.length > 0 &&
                    rows.map((item, index) => (
                      <TopicsRowMemoized
                        key={item.id}
                        referenceBenchmark={referenceBenchmark}
                        sessionUid={sessionUid}
                        item={item}
                        color={colors[index % colors.length]}
                        showCheckbox={mode === 'selection'}
                        showPlayButton={mode === 'normal'}
                        showTopicsSubtitle={showTopicsSubtitle}
                        isRowSelected={isNodeSelected(item)}
                        selectNode={selectNode}
                        highlighted={hoveredNodeId === item.id}
                        setHoveredNodeId={setHoveredNodeId}
                        openKeyTopicsDrawer={openKeyTopicsDrawer}
                      />
                    ))}
                </>
              )}
            </Tbody>
          </Table>
        </Box>
        {otherRow && (
          <>
            <Divider my={4} />
            <Box p={0}>
              <Table w="100%" variant="key-topics-other">
                <Tbody>
                  <TopicsRowMemoized
                    key={otherRow.id}
                    color={
                      rows
                        ? colors[(rows.length ?? 0) % colors.length]
                        : undefined
                    }
                    item={otherRow}
                    referenceBenchmark={referenceBenchmark}
                    sessionUid={sessionUid}
                    showCheckbox={false}
                    showPlayButton={mode === 'normal'}
                    isRowSelected={false}
                    selectNode={mode === 'normal' ? selectNode : undefined}
                    highlighted={hoveredNodeId === otherRow.id}
                    setHoveredNodeId={setHoveredNodeId}
                    showTopicsSubtitle={false}
                    openKeyTopicsDrawer={openKeyTopicsDrawer}
                  />
                </Tbody>
              </Table>
            </Box>
          </>
        )}
      </Flex>
    )
  },
)
