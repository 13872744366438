import { Button } from '@capturi/ui-components'
import React from 'react'
import { useCopyCasesList } from './useCopyCasesList'

type Props = { integrationType: string }

const ViewListButton: React.FC<Props> = ({ integrationType }) => {
  const { isPending, mutate } = useCopyCasesList()
  return (
    <Button isLoading={isPending} onClick={() => mutate({ integrationType })}>
      Copy ticket list to clipboard
    </Button>
  )
}

export default ViewListButton
