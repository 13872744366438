import { t } from '@lingui/macro'
import { FC, useCallback } from 'react'
import { MdInbox } from 'react-icons/md'

import { MultiValueFilterItem } from '../useTextFilter'
import { BasePopoverSelectFilter } from './BasePopoverSelectFilter'
import { TextFilterComponentProps } from './types'

export const StatusFilter: FC<
  TextFilterComponentProps<MultiValueFilterItem> & {
    isOpen: boolean
    onOpen: (itemUid: string) => void
  }
> = (props) => {
  const memoizedOnChangeValue = useCallback(
    (item: {
      uid: string
      values: string[] | null
      inverted: boolean
    }) => {
      props.onChangeValue({
        ...item,
        filterType: 'statusFilters',
      })
    },
    [props.onChangeValue],
  )

  return (
    <BasePopoverSelectFilter
      {...props}
      filter={{ name: 'Status' }}
      singularEntityName={t`Status`}
      pluralEntityName={t`Status(es)`}
      iconComponent={MdInbox}
      onChangeValue={memoizedOnChangeValue}
    />
  )
}
