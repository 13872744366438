import type { ResponseError } from '@capturi/request'
import request from '@capturi/request/src/request'
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from '@capturi/ui-components'
import { BaseModalComponentProps } from '@capturi/use-modal'
import { Input } from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useMutation } from '@tanstack/react-query'
import React, { useState } from 'react'

type Props = {
  integrationUid: string
  onSubmit: (tsControl: number) => void
} & BaseModalComponentProps

export const Etrack1FindTsControl: React.FC<Props> = ({
  onClose,
  onSubmit,
  integrationUid,
}) => {
  const [date, setDate] = useState('')
  const toast = useToast()

  const { mutate, isPending } = useMutation<number, ResponseError, string>({
    mutationFn: (date: string) =>
      request.post<number>(`importer/etrack1/${integrationUid}/tscontrol`, {
        json: { pointInTime: date },
      }),
  })

  const handle = () => {
    mutate(date, {
      onSuccess: (tsControl) => {
        toast({
          status: 'info',
          title: t`Updated tsControl`,
          description: t`New value: ${tsControl}`,
        })

        onSubmit(tsControl)
      },
      onError: (error) => {
        toast({
          status: 'error',
          title: t`Could not find tsControl from date`,
          description: error.message,
        })
      },
    })
  }

  return (
    <Modal isOpen onClose={onClose}>
      <ModalOverlay>
        <ModalContent>
          <ModalHeader>
            <Trans>Find tsControl</Trans>
          </ModalHeader>

          <ModalCloseButton />
          <ModalBody>
            <Input
              type="datetime-local"
              onChange={(e) => setDate(e.target.value)}
              value={date}
            />
          </ModalBody>
          <ModalFooter justifyContent="flex-end">
            <Button mr="4" onClick={onClose} isDisabled={isPending}>
              <Trans>Cancel</Trans>
            </Button>
            <Button primary onClick={handle} isLoading={isPending}>
              <Trans>Ok</Trans>
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
