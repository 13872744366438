import { t } from '@lingui/macro'
import React, { useCallback } from 'react'
import { IoMdPricetag } from 'react-icons/io'

import { MultiValueFilterItem } from '../useTextFilter'
import { BasePopoverSelectFilter } from './BasePopoverSelectFilter'
import { TextFilterComponentProps } from './types'

export const LabelsFilter: React.FC<
  TextFilterComponentProps<MultiValueFilterItem> & {
    isOpen: boolean
    onOpen: (itemUid: string) => void
  }
> = (props) => {
  const memoizedOnChangeValue = useCallback(
    (item: {
      uid: string
      values: string[] | null
      inverted: boolean
    }) => {
      props.onChangeValue({
        ...item,
        filterType: 'tagFilters',
      })
    },
    [props.onChangeValue],
  )

  return (
    <BasePopoverSelectFilter
      {...props}
      filter={{ name: 'Tags' }}
      singularEntityName={t`Label`}
      pluralEntityName={t`Label(s)`}
      iconComponent={IoMdPricetag}
      onChangeValue={memoizedOnChangeValue}
    />
  )
}
