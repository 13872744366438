import { useFeatureFlags } from '@capturi/feature-flags'
import { MagicBox } from '@capturi/ui-components'
import {
  Card,
  CardHeader,
  HStack,
  Icon,
  IconButton,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import React from 'react'
import { MdKeyboardArrowRight, MdLaunch } from 'react-icons/md'
import { KeyTopicConversation } from '../../../pages/KeyTopics/hooks/types'
import { useCaseDetailsDrawer } from '../../CaseDetailsDrawer'

// optional date format
const dateFormat: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
  hour: 'numeric',
  minute: 'numeric',
}

/**
 * Minimal "case-like" list item for KeyTopicConversation
 */
type Props = {
  conversation: KeyTopicConversation
}

export const TextCaseListItem: React.FC<Props> = ({ conversation }) => {
  const { dateTime, subject, userName, rootCause, keyTopic, keySubTopic } =
    conversation
  const openCaseDetailsDrawer = useCaseDetailsDrawer()
  const { aiFeatures } = useFeatureFlags()

  const handleOpenConversationDetails = () => {
    openCaseDetailsDrawer({
      caseUid: conversation.uid,
    })
  }

  return (
    <ListItem>
      <Card border={0} borderRadius="4px" shadow="md" overflow="hidden">
        <CardHeader
          bg="gray.200"
          cursor="pointer"
          px={4}
          py={2}
          onClick={handleOpenConversationDetails}
          data-group
          _hover={{ bg: 'gray.300' }}
        >
          <VStack align="start" gap={0}>
            <HStack justifyContent="space-between" w="100%">
              <Text
                fontSize="md"
                fontWeight="medium"
                _groupHover={{ textDecoration: 'underline' }}
              >
                {subject}
              </Text>
              <Text
                _groupHover={{ visibility: 'hidden' }}
                fontSize="sm"
                textAlign="right"
                textColor="gray.600"
              >
                {i18n.date(dateTime, dateFormat)}
              </Text>
              <IconButton
                aria-label={t`Go to conversation`}
                display="none"
                _groupHover={{ display: 'block' }}
                _hover={{ background: 'none' }}
                icon={<MdLaunch />}
                onClick={(e) => {
                  e.stopPropagation()
                  handleOpenConversationDetails()
                }}
                size="2xs"
                textColor="gray.600"
                variant="ghost"
              />
            </HStack>
            <HStack
              justifyContent={aiFeatures ? 'space-between' : 'flex-end'}
              fontSize="xs"
              textColor="gray.600"
              w="100%"
            >
              {aiFeatures && (
                <HStack gap={0}>
                  {keyTopic && <Text>{keyTopic}</Text>}
                  {keyTopic && keySubTopic && (
                    <Icon as={MdKeyboardArrowRight} />
                  )}
                  {keySubTopic && <Text>{keySubTopic}</Text>}
                </HStack>
              )}
              <Text noOfLines={1}>{userName}</Text>
            </HStack>
          </VStack>
        </CardHeader>
        {aiFeatures && rootCause && rootCause.length > 0 && (
          <MagicBox
            pl={4}
            pr={2}
            pt={2}
            pb={3}
            borderTopRightRadius="0px"
            borderTopLeftRadius="0px"
            borderBottomLeftRadius="4px"
            borderBottomRightRadius="4px"
          >
            <Text cursor="text" whiteSpace="pre-wrap">
              {rootCause}
            </Text>
          </MagicBox>
        )}
      </Card>
    </ListItem>
  )
}
