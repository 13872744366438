import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import type { ConfigField } from '../components/Field'
export type TextIntegrationConfigSchema = {
  integrationType: string
  name: string
  color: string
  description?: string
  auth: ConfigField[]
  options: ConfigField[]
  customFields: string[]
  filters: ConfigField[]
  continuationToken: 'date' | 'string' | 'number'
}

export type TextImporterConfig = {
  auth: Record<string, unknown>
  filters: Record<string, string | string[] | boolean>
  customFields: string[]
  options: Record<string, string | string[] | boolean>
  continuationToken: Date | string
  uid: string
  friendlyName: string
  isActive: boolean
  organizationUid: string
  lastRunStarted: Date | null
  lastRunEnded: Date | null
}

export const useListTextImporter = (
  urlKey: string,
): UseQueryResult<TextImporterConfig[], ResponseError> =>
  useQuery({
    queryKey: ['importers', 'text', urlKey],
    queryFn: () => request.get(`importer/${urlKey}/list`),
  })
export const useTextImporterConfig = (
  integrationType: string,
  integrationKey: string,
): UseQueryResult<TextImporterConfig, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'text', integrationType, integrationKey],
    queryFn: () => request.get(`importer/${integrationType}/${integrationKey}`),
  })
export const useTextImporterSchema = (
  integrationType: string,
  integrationKey: string,
): UseQueryResult<TextIntegrationConfigSchema, ResponseError> =>
  useQuery({
    queryKey: ['importers', 'text', 'schema', integrationType, integrationKey],
    queryFn: () =>
      request.get(`importer/${integrationType}/${integrationKey}/schema`),
  })

export type PatchTextImporterConfig = {
  customFields: string[]
  filters: Record<string, string | boolean | string[]>
  integrationOptions?: Record<string, string | boolean | string[]>
  continuationToken?: string | Date
}

export const useAddTextImporter = (
  integrationType: string,
): UseMutationResult<
  TextImporterConfig,
  ResponseError,
  {
    organizationUid: string
    friendlyName: string
    auth: unknown
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: (payload) =>
      request.post(`importer/${integrationType}/add`, {
        json: {
          filters: {},
          customFields: [],
          continuationToken: new Date(),
          ...payload,
        },
      }),
    onSuccess: (importer) => {
      queryClient.setQueryData<TextImporterConfig[]>(
        ['importers', 'text', integrationType],
        (old) => (old ? [...old, importer] : [importer]),
      )
    },
  })
}
export const useTestTextImporterAuth = (
  integrationType: string,
): UseMutationResult<
  { success: true },
  ResponseError,
  { auth: TextImporterConfig['auth'] },
  ResponseError
> => {
  return useMutation({
    mutationFn: ({ auth }) =>
      request.post<{ success: true }>(`importer/${integrationType}/test-auth`, {
        json: {
          auth,
        },
      }),
  })
}
export const useUpdateTextImporter = (
  integrationType: string,
  uid: string,
): UseMutationResult<
  { uid: string },
  ResponseError,
  {
    customFields?: string[]
    filters?: Record<string, unknown>
    options?: Record<string, unknown>
    continuationToken?: string | Date
  },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationKey: ['importers', 'text', integrationType, uid],
    mutationFn: (payload) =>
      request.patch(`importer/${integrationType}/${uid}`, {
        json: payload,
      }),
    onSuccess: (importer) => {
      queryClient.setQueryData<{ uid: string }>(
        ['importers', 'text', integrationType, uid],
        (oldData) => {
          if (!oldData) {
            return importer
          }
          return { ...oldData, ...importer }
        },
      )
    },
  })
}

export const useToggleTextImporter = (
  urlKey: string,
): UseMutationResult<
  { uid: string },
  ResponseError,
  { uid: string; action: 'start' | 'stop' },
  ResponseError
> => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ uid, action }) =>
      request.post(`importer/${urlKey}/${uid}/${action}`),
    onSuccess: (_, { uid, action }) => {
      queryClient.setQueryData<{ uid: string }[]>(
        ['importers', 'text', urlKey],
        (oldData) => {
          if (!oldData) {
            return []
          }
          return oldData.map((d) =>
            d.uid === uid ? { ...d, isActive: action === 'start' } : d,
          )
        },
      )
    },
  })
}
