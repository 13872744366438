import request, { ResponseError } from '@capturi/request'
import { useOrganization } from '@capturi/stores'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useCallback } from 'react'

const CACHE_KEY = ['askAi', 'questions']

type Question = {
  uid: string
  name: string
  question: string
}

type Answer = {
  uid: string
  answer: string
}

type QuestionResponseModel = {
  conversations: Answer[]
}

export const useAiQuestions = (): UseQueryResult<Question[], ResponseError> => {
  const { conversationLanguage } = useOrganization()
  const compare = useCallback(Intl.Collator(conversationLanguage).compare, [])
  const sortingSelect = useCallback(
    (data: Question[]) => data.sort((a, b) => compare(a.name, b.name)),
    [compare],
  )

  return useQuery({
    queryKey: CACHE_KEY,
    queryFn: async () => {
      const result = await request.get<{ questions: Question[] }>(
        'ask-ai/questions?api-version=3.3',
      )
      return result.questions
    },
    select: sortingSelect,
  })
}

export const useAiQuestionAnswers = (config: {
  conversationUids: string[]
  question?: string
}): UseQueryResult<Answer[], ResponseError> =>
  useQuery({
    queryKey: [...CACHE_KEY, config.conversationUids, config.question],
    queryFn: async () => {
      const result = await request.post<QuestionResponseModel>(
        'ask-ai/ask?api-version=3.3',
        {
          json: {
            question: config.question,
            conversationUids: config.conversationUids,
          },
        },
      )
      return result.conversations
    },
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!config.question && !!config.conversationUids.length,
  })
