import { PhoneFilterValues } from '@capturi/api-filters'
import { useCurrentUser } from '@capturi/core'
import {
  FilterMenuPlacementProvider,
  FilterPeriodProvider,
  PhoneFilter,
  toDuration,
  useFilterDefinitions,
  useFilterPeriodContext,
} from '@capturi/filters'
import { FormControl, FormLabel } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { FC } from 'react'

const SubscriptionFilter: FC<{
  filter: Partial<PhoneFilterValues>
  setFilter?: (state: Partial<PhoneFilterValues>) => void
  readonly?: boolean
}> = ({ filter, setFilter, readonly }) => {
  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const { periodDef } = useFilterPeriodContext()
  const duration = toDuration(filter.duration?.max, filter.duration?.min)
  return (
    <FilterPeriodProvider defaultPeriod={periodDef}>
      <FilterMenuPlacementProvider value="bottom-start">
        <FormControl>
          <FormLabel>
            <Trans>Filter</Trans>
          </FormLabel>
          <PhoneFilter
            //Ugly hack to make it update upon changes
            key={JSON.stringify(filter)}
            isReadOnly={readonly}
            filterDefinitions={filterDefinitions}
            state={{
              channel: 'phone',
              values: {
                ...filter,
                duration: duration,
              },
            }}
            onStateChange={(filter) => {
              setFilter?.(filter.values)
            }}
          />
        </FormControl>
      </FilterMenuPlacementProvider>
    </FilterPeriodProvider>
  )
}

export default SubscriptionFilter
