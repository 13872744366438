import request, { ResponseError } from '@capturi/request'
import {
  UseMutationResult,
  UseQueryResult,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'

const generateUrl = (organizationUid: string): string =>
  `systemadmin/organization/${organizationUid}/settings?api-version=1`
const cacheKey = 'systemSettings'

export type AiProvider =
  | 'OpenAI'
  | 'AzureWithAnonymization'
  | 'AzureWithoutAnonymization'

export type SystemSettings = {
  //TODO: add custom props
  repeatCalls: {
    enabled: boolean
    distanceBetweenCalls: number
  }
  //cannot be null, even though swagger says so
  ai: {
    enabled: boolean
    useGlobalCache: boolean
    keyTopicThreshold: number | null
    maxSummaryWords: number | null
    summaryPrompt: string | null
    summaryPromptUid: string | null
    provider: AiProvider
    disableNonConsentAnalysis: boolean
  }
  //cannot be null, even though swagger says so
  deletionStrategy: {
    conversationSoftTimeToLive: string | null
    conversationHardTimeToLive: string | null
    caseTimeToLive: string
    audioSnippetTimeToLive: string | null
    anonymizeAsrData: boolean
    deleteCustomer: boolean
  }
  deleted: string | null
  conversationsWithoutConsent: boolean
  wordCloud: { blacklistNgrams: string[] } | null
}

export const useSystemSettings = (
  organizationUid: string,
): UseQueryResult<SystemSettings, ResponseError> =>
  useQuery({
    queryKey: [cacheKey, 'organisation', organizationUid],
    queryFn: () => request.get(generateUrl(organizationUid)),
    refetchOnWindowFocus: false,
  })

export const useUpdateSystemSettings = (
  organizationUid: string,
): UseMutationResult<
  SystemSettings,
  ResponseError,
  Partial<SystemSettings>
> => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [cacheKey, 'organisation', organizationUid],
    mutationFn: (settings) => {
      return request.patch<SystemSettings>(generateUrl(organizationUid), {
        json: settings,
      })
    },
    onSuccess: (newDate) => {
      queryClient.setQueryData<SystemSettings>(
        [cacheKey, 'organisation', organizationUid],
        (oldData) => {
          if (!oldData) {
            return newDate
          }
          return { ...oldData, ...newDate }
        },
      )
    },
  })
}
