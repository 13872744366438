import { PhoneFilterValues } from '@capturi/api-filters'
import request, { ResponseError } from '@capturi/request'
import {
  InfiniteData,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query'

import { SummariesResponseModel, Summary } from '../models'

interface UseSummariesParams {
  filterValues: PhoneFilterValues | undefined
  period: {
    from: Date
    to: Date
  }
  pageLimit: number
  enabled: boolean
  searchPhrases?: string[]
}

const getNextPageParam = (lastPage: SummariesResponseModel) =>
  lastPage.continuationToken

const select = (data: InfiniteData<SummariesResponseModel>) => ({
  pages: data.pages.map((page) => page.summaries),
  pageParams: [],
})

export const useSummaries = ({
  filterValues: filter,
  period,
  pageLimit, // ignored when searchPhrases are present
  enabled,
  searchPhrases = [], // when present, pageLimit is ignored on server side
}: UseSummariesParams): UseInfiniteQueryResult<
  InfiniteData<Summary[]>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: [
      'summaries',
      filter,
      period.from,
      period.to,
      pageLimit,
      searchPhrases,
    ],
    queryFn: async ({ pageParam }) =>
      await request.post<SummariesResponseModel>(
        'conversations/summaries?api-version=3.3',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: pageLimit,
                fromInclusive: period.from,
                toInclusive: period.to,
                sortDirection: 1,
                searchPhrases,
              },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select,
    enabled,
    refetchOnWindowFocus: false,
  })
}

export const useAskAiSummaryList = ({
  filterValues: filter,
  period,
  pageLimit, // ignored when searchPhrases are present
  enabled,
  searchPhrases = [], // when present, pageLimit is ignored on server side
}: UseSummariesParams): UseInfiniteQueryResult<
  InfiniteData<Summary[]>,
  ResponseError
> => {
  return useInfiniteQuery({
    queryKey: [
      'ask-ai/conversations',
      filter,
      period.from,
      period.to,
      pageLimit,
      searchPhrases,
    ],
    queryFn: async ({ pageParam }) =>
      await request.post<SummariesResponseModel>(
        'ask-ai/conversations?api-version=3.3',
        {
          json: pageParam
            ? { continuationToken: pageParam }
            : {
                ...filter,
                limit: pageLimit,
                fromInclusive: period.from,
                toInclusive: period.to,
                sortDirection: 1,
                searchPhrases,
              },
        },
      ),
    getNextPageParam,
    initialPageParam: null,
    select,
    enabled,
    refetchOnWindowFocus: false,
  })
}
