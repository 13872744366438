import { Spinner } from '@capturi/ui-components'
import {
  Box,
  Card,
  CardBody,
  Center,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react'
import { css } from '@emotion/react'
import React from 'react'
import {
  SystemSettings,
  useSystemSettings,
  useUpdateSystemSettings,
} from '../../../../shared/useSystemSettings'
import { Title } from '../../../../utils'
import AiProviderSelect from './AiProviderSelect'
import { BackSyncAi } from './BackSyncAi'
import { CustomSummaryPrompts } from './CustomSummaryPrompts'
import KeytopicThreshold from './KeytopicThreshold'

type Props = { organizationUid: string }

const AiConfig: React.FC<Props> = ({ organizationUid }) => {
  const toast = useToast()

  const { data: systemSettings } = useSystemSettings(organizationUid)
  const { mutate: updateSystemSettings, isPending: isUpdatingSystemSettings } =
    useUpdateSystemSettings(organizationUid)

  if (!systemSettings)
    return (
      <Card my="4">
        <CardBody>
          <Title>AI</Title>
          <Flex alignItems="center" justifyContent="center" height="127px">
            <Spinner delay={0} />
          </Flex>
        </CardBody>
      </Card>
    )
  const isEnabled = systemSettings.ai.enabled

  const handleUpdate = (payload: Partial<SystemSettings['ai']>) => {
    if (systemSettings.ai)
      updateSystemSettings(
        {
          ai: {
            ...systemSettings.ai,
            ...payload,
          },
        },
        {
          onError: (e) => {
            toast({
              status: 'error',
              title: 'Could not update ai setting',
              description: e.message,
            })
          },
          onSuccess: () => {
            toast({
              status: 'success',
              title: `${Object.keys(payload).join(', ')} updated`,
            })
          },
        },
      )
  }

  return (
    <Box
      my="4"
      boxShadow="0px 0px 1px 0px #0000000A, 0px 0px 2px 0px #0000000F, 0px 4px 8px 0px #0000000A"
      background="white"
      borderRadius="8px"
      position="relative"
      overflow="hidden"
      p="10px"
      w="100%"
      css={
        isEnabled
          ? css`
    ::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px; 
      padding: 1px; 
      overflow: hidden;
      background:linear-gradient(
        180deg,
        rgba(239, 58, 66, 0.5) 0%,
        rgba(255, 178, 32, 0.5) 49%,
        rgba(255, 145, 179, 0.5) 100%
      ); 
      -webkit-mask: 
         linear-gradient(#fff 0 0) content-box, 
         linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
              mask-composite: exclude; 
    }
`
          : undefined
      }
    >
      <Flex alignItems="center">
        <Switch
          size="sm"
          color="primary"
          id="generateSummaryWhenConversationIsCreated"
          isChecked={systemSettings?.ai?.enabled}
          onChange={(e) => {
            handleUpdate({ enabled: e.currentTarget.checked })
          }}
          mr={2}
          isDisabled={isUpdatingSystemSettings}
        />

        <Title>AI</Title>
      </Flex>
      <Flex>
        <Box mt={4}>
          <Text whiteSpace="nowrap">Global Cache</Text>

          <FormControl
            mt={4}
            isDisabled={!isEnabled}
            display="flex"
            alignItems="center"
          >
            <Switch
              size="sm"
              color="primary"
              isChecked={systemSettings.ai.useGlobalCache}
              onChange={(e) => {
                handleUpdate({ useGlobalCache: e.currentTarget.checked })
              }}
              mr={2}
              isDisabled={isUpdatingSystemSettings || !isEnabled}
            />
            <FormLabel m="0" whiteSpace="nowrap">
              Enable AI cache
            </FormLabel>
          </FormControl>
          <FormControl
            mt={4}
            isDisabled={!isEnabled}
            display="flex"
            alignItems="center"
          >
            <Switch
              size="sm"
              color="primary"
              isChecked={systemSettings.ai.disableNonConsentAnalysis}
              onChange={(e) => {
                handleUpdate({
                  disableNonConsentAnalysis: e.currentTarget.checked,
                })
              }}
              mr={2}
              isDisabled={isUpdatingSystemSettings || !isEnabled}
            />
            <FormLabel m="0" whiteSpace="nowrap">
              Disable non-consent analysis
            </FormLabel>
          </FormControl>
          <Center>
            <Divider orientation="horizontal" my={3} />
          </Center>
          <FormControl>
            <Text mb={1} whiteSpace="nowrap">
              Set AI provider for the org
            </Text>
            <AiProviderSelect
              organizationUid={organizationUid}
              ai={systemSettings.ai}
            />
          </FormControl>
        </Box>
        <Center>
          <Divider orientation="vertical" mx={3} />
        </Center>
        <Box mt={4}>
          <KeytopicThreshold
            aiData={systemSettings.ai}
            organizationUid={organizationUid}
            isEnabled={isEnabled}
          />
        </Box>
        <Center>
          <Divider orientation="vertical" mx={3} />
        </Center>
        <Box mt={4}>
          <CustomSummaryPrompts
            organizationUid={organizationUid}
            isEnabled={isEnabled}
          />
          <Divider orientation="horizontal" my={3} />

          <BackSyncAi organizationUid={organizationUid} />
        </Box>
      </Flex>
    </Box>
  )
}

export default AiConfig
