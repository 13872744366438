import { useAskAiSummaryList } from '@capturi/api-summaries'
import { useCurrentUser } from '@capturi/core'
import {
  FilterPeriodSelectContainer,
  SingleSegmentContainer,
  useFilterDefinitions,
  useFilterPeriodContext,
  useFirstPhoneSegmentState,
} from '@capturi/filters'
import { EmptyStateIcon, usePageTitle } from '@capturi/react-utils'
import { getErrorMessage } from '@capturi/request'
import {
  Button,
  ContentPlaceholder,
  Emoji,
  PageHeading,
} from '@capturi/ui-components'
import {
  Box,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { Trans, t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import React, { useRef, useState, useMemo, useCallback, useEffect } from 'react'
import { MdArrowUpward } from 'react-icons/md'

import { globalSearchStore } from 'state/useGlobalSearch'
import { SearchInputWithCounters } from '../../components/Search/SearchInputWithCounters'
import { AskAiAnswer } from './Components/AskAIAnswer'
import QuestionInput from './Components/QuestionInput'
import {
  ASK_AI_LOAD_MORE_STEP,
  useAskAiSummaries,
} from './hooks/useAskAiSummaries'

const PAGE_LIMIT = 5

const AskAIPage: React.FC = () => {
  usePageTitle('Ask AI')

  const scrollToRef = useRef<HTMLDivElement | null>(null)
  const toast = useToast()
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const prevResetKeyRef = useRef<string>()

  const { period } = useFilterPeriodContext()

  const [customQuestion, setCustomQuestion] = useState<string>('')

  const segmentState = useFirstPhoneSegmentState()
  const searchPhrases = useStore(globalSearchStore)

  const currentUser = useCurrentUser()
  const filterDefinitions = useFilterDefinitions(currentUser)
  const {
    data: summariesData,
    isLoading,
    isPending,
    isFetchingNextPage,
    error,
    hasNextPage,
    fetchNextPage,
    refetch: fetchSummaries,
  } = useAskAiSummaryList({
    filterValues: segmentState.values,
    period,
    searchPhrases,
    pageLimit: PAGE_LIMIT,
    enabled: false,
  })

  const resetKey = useMemo(() => {
    return JSON.stringify({
      filter: segmentState.values,
      period: {
        from: period.from?.toISOString(),
        to: period.to?.toISOString(),
      },
      searchPhrases,
    })
  }, [segmentState.values, period, searchPhrases])

  // when searchPhrases are present, useSummaries ignores pageLimit and returns all summaries
  // we therefore limit the amount of ask-ai summaries we request at a time (paginate on client side)
  const {
    aiSummariesToShow,
    handleLoadMore,
    hasNextPage: canLoadMore,
    resetAiSummariesPagination,
  } = useAskAiSummaries({
    summariesData,
    fetchNextPage,
    hasNextPage,
    resetKey: `${resetKey}:${customQuestion}`,
  })

  const allSummaries = useMemo(() => {
    return (summariesData?.pages ?? []).flat()
  }, [summariesData])

  const displayedSummaries = useMemo(() => {
    return allSummaries.slice(0, aiSummariesToShow)
  }, [allSummaries, aiSummariesToShow])

  const isEmpty = displayedSummaries.length === 0 && !isPending

  // Reset submit button when resetKey changes
  useEffect(() => {
    if (isSubmitDisabled && prevResetKeyRef.current !== resetKey) {
      setIsSubmitDisabled(false)
    }
    prevResetKeyRef.current = resetKey
  }, [resetKey, isSubmitDisabled])

  const handleSummaries = useCallback(
    async (question: string) => {
      resetAiSummariesPagination()
      setCustomQuestion(question.trim())
      try {
        await fetchSummaries()
      } catch (error) {
        const errorMessage = getErrorMessage(error)
        toast({
          title: t`Something went wrong when loading summaries. Please try again.`,
          description: errorMessage,
          status: 'error',
        })
      }
    },
    [fetchSummaries, toast, resetAiSummariesPagination],
  )

  const handleSubmit = useCallback(
    (question: string) => {
      setIsSubmitDisabled(true)
      handleSummaries(question)
    },
    [handleSummaries],
  )

  const handleQuestionChange = useCallback(() => {
    setIsSubmitDisabled(false)
  }, [])

  if (error) {
    return (
      <ContentPlaceholder.Container mt={20}>
        <EmptyStateIcon />
        <ContentPlaceholder.Heading>
          <Trans>Whoops! There has been an error</Trans>
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          <Trans>Try reloading the page</Trans>
        </ContentPlaceholder.Body>
        <ContentPlaceholder.Footer>
          <Button primary onClick={() => location.reload()}>
            <Trans>Reload page</Trans>
          </Button>
        </ContentPlaceholder.Footer>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Flex flexDir="column" w="100%" overflowY="hidden" h="100%">
      <Flex
        className="conversations-container"
        flexDir="column"
        h="100%"
        overflowY="auto"
        w="100%"
        gap={4}
      >
        <Box mb={4}>
          <Flex align="start" justify="space-between" mb={6}>
            <PageHeading mb={6}>
              <Trans>Ask AI</Trans>
            </PageHeading>

            <HStack alignItems="flex-start">
              <SearchInputWithCounters />
              <Box>
                <FilterPeriodSelectContainer />
              </Box>
            </HStack>
          </Flex>
          <SingleSegmentContainer
            disabledChannels={['email']}
            filterDefinitions={filterDefinitions}
          />
        </Box>
        <Heading fontSize="4xl" textAlign="center">
          <Trans>Ask each conversation...</Trans>
        </Heading>
        <Box mx="auto" w="680px">
          <QuestionInput
            handleSubmit={handleSubmit}
            hasPostedState={!isPending}
            isSubmitDisabled={isSubmitDisabled}
            onQuestionChange={handleQuestionChange}
          />
        </Box>
        {isEmpty ? (
          <Flex mt={2} direction="column" alignItems="center">
            <Emoji symbol="👀" fontSize="70px" />
            <Text fontSize="lg">
              <Trans>No results</Trans>
            </Text>
            <Text fontSize="sm" color="textMuted" textAlign="center">
              <Trans>
                Try to increase the period up in the right corner or make your
                search terms broader
              </Trans>
            </Text>
          </Flex>
        ) : (
          !isPending && (
            <Flex
              flexDir="column"
              gap={3}
              w="960px"
              m="0 auto"
              height="100%"
              h="100%"
            >
              <Box w="960px" m="0 auto" height="100%" pb={8}>
                <VStack gap="1" h="100%">
                  <HStack
                    ref={scrollToRef}
                    w="100%"
                    alignItems="center"
                    minH="28px"
                    justifyContent="space-between"
                  >
                    <Heading fontSize="2xl" w="121px">
                      <Trans>Answers</Trans>
                    </Heading>
                    <Text fontSize="xs" color="gray.400">
                      <Trans>Generated by Capturi AI</Trans>
                    </Text>
                  </HStack>
                  <Box h="100%" w="100%" textAlign="center">
                    {isLoading ? (
                      <Spinner color="primary.500" mb={3} />
                    ) : (
                      <>
                        {displayedSummaries.map((summary, idx) => {
                          const nextSummaryIndex = idx + 1

                          // If we've hit the end of the first page
                          const showsPageBoundary =
                            nextSummaryIndex % ASK_AI_LOAD_MORE_STEP === 0 &&
                            nextSummaryIndex < displayedSummaries.length

                          return (
                            <React.Fragment key={summary.uid}>
                              <AskAiAnswer
                                summaryData={summary}
                                question={customQuestion}
                              />
                              {showsPageBoundary && (
                                <Flex
                                  flexDir="row"
                                  alignItems="center"
                                  position="relative"
                                  py={6}
                                  mt="-16px"
                                  gap={4}
                                >
                                  <Divider borderColor="gray.300" />
                                  <Text
                                    as="span"
                                    color="gray.600"
                                    flex="1 0 auto"
                                  >
                                    <Trans>
                                      Page{' '}
                                      {nextSummaryIndex /
                                        ASK_AI_LOAD_MORE_STEP +
                                        1}
                                    </Trans>
                                  </Text>
                                  <Divider borderColor="gray.300" />
                                </Flex>
                              )}
                            </React.Fragment>
                          )
                        })}
                      </>
                    )}
                  </Box>
                  <Text
                    fontSize="xs"
                    fontStyle="italic"
                    color="gray.500"
                    mb={2}
                  >
                    <Trans>
                      Note, Ask AI can make mistakes. Always check important
                      information and do not rely solely on answers when making
                      decisions.
                    </Trans>
                  </Text>
                  <Button
                    onClick={handleLoadMore}
                    isDisabled={!canLoadMore || isFetchingNextPage}
                    isLoading={isFetchingNextPage}
                  >
                    {canLoadMore ? (
                      <Text>
                        <Trans>Load more</Trans>
                      </Text>
                    ) : (
                      <Trans>No more conversations</Trans>
                    )}
                  </Button>
                  {allSummaries.length > PAGE_LIMIT && (
                    <Tooltip label={t`Scroll to top`} hasArrow placement="top">
                      <IconButton
                        onClick={() =>
                          scrollToRef?.current?.scrollIntoView({
                            behavior: 'smooth',
                            block: 'end',
                          })
                        }
                        mt={4}
                        aria-label={t`Thumbs down`}
                        rounded="100%"
                        color="gray.800"
                        icon={<MdArrowUpward />}
                        size="md"
                      />
                    </Tooltip>
                  )}
                </VStack>
              </Box>
            </Flex>
          )
        )}
      </Flex>
    </Flex>
  )
}

export default AskAIPage
